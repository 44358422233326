import { useParams } from "react-router-dom";

import { PaginationProvider } from "../../providers/PaginationProvider/PaginationProvider";

import { AssignPackagesWrapper } from "../../components"

export default function AssignPackagesPage() {

    const { uuid } = useParams()

    return (
        <PaginationProvider>
            <AssignPackagesWrapper uuid={uuid} />
        </PaginationProvider>
    )
}