import React from 'react';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import lock from '../../../../../assets/icons/lock.svg';

export default function InputField({ offer, error, inputRef, isDisabled = false, handleChange, handleValid }) {

    return (
        <InputGroup className={`w-37 ${error ? 'input-group-error' : ''}`}>
            <InputGroup.Text id="accessCode"><img src={lock} alt='' /></InputGroup.Text>
            <Form.Control
                ref={inputRef}
                placeholder="Code"
                id="code"
                name="accessCode"
                maxLength="15"
                aria-describedby="accessCode"
                pattern='^[0-9a-zA-Z]{0,15}$'
                disabled={isDisabled}
                value={offer?.accessCode}
                onChange={handleChange}
                onBlur={handleValid}
            />
        </InputGroup>
    );
}
