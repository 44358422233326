import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';
import { FromPageProvider } from '../../providers/FromPageProvider';

import AuthService from '../../utilities/services/auth.service';

import { getPackageHolders } from '../../utilities/api';

import Card from 'react-bootstrap/Card'

import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';
import { EmptyContainer } from '../EmptyContainer';
import { HoldersList } from './HoldersList';
import { FanProfileWrapper } from './FanProfileWrapper';

export default function PackageHoldersWrapper({ uuid, fanId }) {

    const location = useLocation()
    const organization = AuthService.getOrg()[0];

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { updateIsPackagePublished, updateIsPackageOnsale, updateIsPackageSoldout, updateHasPackageEnded } = useContext(PackageDetailsContext)

    const [hasPermission, setHasPermission] = useState(true);

    const [packageHolders, setPackageHolders] = useState({})

    useEffect(() => {
        showLoading()
        loadPackageHolders()
    }, [])

    const loadPackageHolders = () => {
        getPackageHolders(uuid, organization.uuid, '')
            .then((res) => {
                setPackageHolders(res.data)
                // update context 
                updateHasPackageEnded()
                updateIsPackageSoldout()
                updateIsPackageOnsale()
                updateIsPackagePublished()
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <div className='position-relative'>
                        <section
                            className={`max-width-wrapper ${!hasPermission ? "overlay" : ""
                                }`}
                        >
                            {(!location.pathname.includes('/profile')) ? (
                                <section>
                                    <header className='section-header section-heading section-heading--secondary'>
                                        <div className="section-heading">
                                            <h1>Package holders</h1>
                                            <p className='section-header-desc'>Comprehensive list of customers who have purchased season packages</p>
                                        </div>
                                    </header>
                                    {packageHolders?.length > 0 ? (
                                        <HoldersList packageId={uuid} organizationId={organization.uuid} packageHolders={packageHolders} />
                                    ) : (
                                        <Card body>
                                            <EmptyContainer style='center lg'>
                                                <p>
                                                    You have no package holders
                                                </p>
                                            </EmptyContainer>
                                        </Card>
                                    )}
                                </section>
                            ) : (
                                <FromPageProvider>
                                    <FanProfileWrapper id={fanId} />
                                </FromPageProvider>
                            )}
                        </section>

                        {!hasPermission && <NoPermissionsContainer />}
                    </div>
                </>
            )}
        </>
    )
}