import React, { useEffect, useState } from 'react';
import moment from "moment"

import { getTimezoneDate } from '../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import { Offer } from './Offer';

import './offers.scss';

export default function Offers({ isEventOnsale, getOfferStatus, offers, timezone, openInventory, handleClick, getOfferPrice, getSeatCount }) {

    const [openOffers, setOpenOffers] = useState()

    const [exclusiveOffers, setExclusiveOffers] = useState()

    useEffect(() => {
        const openOffers = offers?.filter(offer => offer.inventoryType.toLowerCase() === "open")
        setOpenOffers(sort(openOffers, true))
        const exclusiveOffers = offers?.filter(offer => offer.inventoryType === "exclusive")
        setExclusiveOffers(sort(exclusiveOffers, false))
    }, [offers])

    // sort offers by availability periods - ended offers are sorted last 
    const sort = (offers, isSortingOpen) => {
        const now = getTimezoneDate(moment(), timezone);

        return offers.sort((a, b) => {
            const activeAPeriod = a.availability?.find(period => period.active)
            const activeBPeriod = b.availability?.find(period => period.active)
            const aEndDate = getTimezoneDate(activeAPeriod.ends, timezone);
            const bEndDate = getTimezoneDate(activeBPeriod.ends, timezone);

            // Case 1: Both offers are active
            if (aEndDate.isSameOrAfter(now) && bEndDate.isSameOrAfter(now)) {
                const aStartDate = getTimezoneDate(activeAPeriod.starts, timezone);
                const bStartDate = getTimezoneDate(activeBPeriod.starts, timezone);
                // only if sorting open offers (not exclusive offers)
                if (isSortingOpen) {
                    // only two offers can be on sale at same time (standard admission and another offer)
                    // offers are not sold out and onsale 
                    if (!a.isSoldOut && !b.isSoldOut && aStartDate.isSameOrBefore(now) && bStartDate.isSameOrBefore(now)) {
                        // display other offer before standard admission 
                        // if a is standard admission offer 
                        if (a.id === offers[0]?.id) {
                            return 1 // b comes before a (Standard Admission)
                        } else {
                            // b is standard admission offer 
                            return -1 // a comes before b (Standard Admission)
                        }
                    }

                    return aStartDate - bStartDate; // Sort by start date if both are sold out or not onsale (earliest first)
                }
                return aStartDate - bStartDate; // Sort by start date (earliest first)
            }

            // Case 2: Only one offer is still active
            if (aEndDate.isSameOrAfter(now) && bEndDate.isBefore(now)) {
                return -1; // a is active, b has ended → a comes first
            }
            if (aEndDate.isBefore(now) && bEndDate.isSameOrAfter(now)) {
                return 1; // b is active, a has ended → b comes first
            }

            // Case 3: Both offers have ended, sort by endDate (earliest first)
            return aEndDate - bEndDate;
        })
    }

    return (
        <>
            {offers?.length > 0 && (
                <>
                    {openOffers?.length > 0 && (
                        <Card body className='card--md card--light'>
                            <div className='card-body-heading card-body-heading--xs'>
                                <Card.Title as="h5" className='text-upper'>Open</Card.Title>
                            </div>
                            <Stack gap={2} as="ul">
                                <>
                                    {openOffers.map((offer, id) =>
                                        <Offer
                                            key={id}
                                            offer={offer}
                                            offers={offers}
                                            timezone={timezone}
                                            isEventOnsale={isEventOnsale}
                                            openInventory={openInventory}
                                            isStandard={offers[0]?.id === offer?.id}
                                            handleClick={handleClick}
                                            getPrice={getOfferPrice}
                                            getStatus={getOfferStatus}
                                            getSeatCount={getSeatCount}
                                        />
                                    )}
                                </>
                            </Stack>
                        </Card>
                    )}
                    {exclusiveOffers?.length > 0 && (
                        <Card body className='card--md card--light'>
                            <div className='card-body-heading card-body-heading--xs'>
                                <Card.Title as="h5" className='text-upper'>Exclusive</Card.Title>
                            </div>
                            <Stack gap={2} as="ul">
                                <>
                                    {exclusiveOffers.map((offer, id) =>
                                        <Offer
                                            key={id}
                                            offer={offer}
                                            offers={offers}
                                            timezone={timezone}
                                            isEventOnsale={isEventOnsale}
                                            openInventory={openInventory}
                                            isStandard={offers[0]?.id === offer?.id}
                                            handleClick={handleClick}
                                            getPrice={getOfferPrice}
                                            getStatus={getOfferStatus}
                                            getSeatCount={getSeatCount}
                                        />
                                    )}
                                </>
                            </Stack>
                        </Card>
                    )}
                </>
            )}
        </>

    );
}
