import React, { useState } from 'react';

import { formatCurrency, formatNumber, getSinglarOrPluralWord } from '../../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import { Payment } from './Payment';
import { MarkAsPaidModal } from './MarkAsPaidModal';

export default function TicketsPayments({ data }) {

    const [show, setShow] = useState(false)

    // online or in-person
    const [paymentOption, setPaymentOption] = useState('')

    // cash or cheque
    const [paymentMethod, setPaymentMethod] = useState('cash')

    const [isSaving, setIsSaving] = useState(false)

    const handleShow = () => setShow(true)

    const handleClose = () => {
        setShow(false)
        setPaymentOption('')
        setPaymentMethod('cash')
    }

    // mark as paid handler 
    const handleClick = () => {
        setIsSaving(true)
        setIsSaving(false)
        handleClose()
    }

    const getSeatNumbers = (seats) => {
        if (!seats) return
        let seatNumberArr = seats.map((seat) => Number(seat.seatNumber));
        if (seatNumberArr.length > 1) return `${Math.min(...seatNumberArr)} - ${Math.max(...seatNumberArr)}`
        return seatNumberArr[0]
    }

    return (
        <>
            <div className="card-body-heading card-body-heading--sm">
                <Card.Title as="h5" className='card-title-lg'>Tickets & Payments</Card.Title>
            </div>
            <Stack gap={5} direction="horizontal" className='justify-content-between align-items-start'>
                <div className='flex-grow-1'>
                    <span className='d-block text-upper mb-3 text-muted'>Package</span>
                    <div className="list-item list-item-lg list-item--vertical py-2 list-item--primary gap-4">
                        <Stack gap={2}>
                            <Stack gap={1}>
                                <span className='normal normal-bold'>{data?.history?.package?.name}</span>
                                <span className='small'>{formatNumber(data?.history?.package?.events.length)} {getSinglarOrPluralWord(data?.history?.package?.events.length, 'Event')}</span>
                                <span className='caption'>Sec {data?.history?.details?.fanSeats[0]?.sectionNumber} • Row {data?.history?.details?.fanSeats[0]?.rowNumber} • Seat {getSeatNumbers(data?.history?.details?.fanSeats)}</span>
                            </Stack>
                            <Stack gap={1}>
                                <span className='text-muted caption'>Price</span>
                                <span className='small small-bold'>{formatCurrency(data?.history?.totalAmount)}</span>
                            </Stack>
                        </Stack>
                    </div>
                </div>
                <div className='flex-grow-1'>
                    <span className='d-block text-upper mb-3 text-muted'>Payment Details</span>
                    <Stack gap={4}>
                        <div className="list-item list-item-lg gap-4">
                            <Stack direction="horizontal" gap={3} className='flex-grow-1'>
                                <Stack gap={1}>
                                    <span className='total small'>Total Amount</span>
                                    <span className='small small-bold'>{formatCurrency(data?.history?.totalAmount)}</span>
                                </Stack>
                                <Stack gap={1}>
                                    <span className='small paid'>Paid</span>
                                    <span className='small small-bold'>{formatCurrency(0)}</span>
                                </Stack>
                                <Stack gap={1}>
                                    <span className='small due'>Amount Due</span>
                                    <span className='small small-bold'>{formatCurrency(data?.history?.amountDue)}</span>
                                </Stack>
                                <Stack gap={1}>
                                    <span className='small overdue'>Over Due</span>
                                    <span className='small small-bold'>{formatCurrency(0)}</span>
                                </Stack>
                            </Stack>
                        </div>
                        <div className="list-table list-table--with-bg-dark list-table--with-radius two-col" role="table">
                            <div className="flex-row flex-row--no-borders list-table-header list-table-header" role="rowgroup">
                                <div className='list-table-col list-table-col-header list-table-col-header-sm list-table-col-header--dark' role="columnheader">
                                    <span>Date</span>
                                </div>
                                <div className='list-table-col text-left list-table-col-header list-table-col-header-sm list-table-col-header--dark' role="columnheader">
                                    <span>Amount</span>
                                </div>
                                <div className="list-table-col list-table-col-header list-table-col-header-sm list-table-col-header--dark text-center sm" role="columnheader">
                                    <span>Status</span>
                                </div>
                            </div>
                            <Payment invoice={data?.history} handleShow={handleShow} />
                        </div>
                    </Stack>
                </div>
            </Stack>

            <MarkAsPaidModal show={show} paymentOption={paymentOption} setPaymentOption={setPaymentOption} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} isSaving={isSaving} handleClick={handleClick} handleClose={handleClose} />

        </>
    );
}
