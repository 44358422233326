import { ActionsProvider } from "../../SeatMapWrapper/ConfigureMap/MapConfigurations/ActionsProvider/ActionsProvider";
import { SeatMap } from "../../SeatMapWrapper/ConfigureMap/MapConfigurations/SeatMap";
import { useGAModal } from "../GAModalProvider/GAModalProvider";

export default function PackageMap({ data, background, selectedSeatIds, setSelectedSeatIds }) {
    const SELECTED_COLOR = "#EF466F";
    const { openModal } = useGAModal();

    // save regular seated seats,,,
    const selectRegularSeats = (seatId, seatIdArray) => {
        setSelectedSeatIds((prevSelected) => {
            const newSelected = { ...prevSelected };
            if (!newSelected.seats) {
                newSelected.seats = {};
            }
            const addSeat = (id) => {
                if (!newSelected.seats[id]) {
                    newSelected.seats[id] = [];
                }
                if (!newSelected.seats[id].includes(id)) {
                    newSelected.seats[id].push(id);
                }
            };
            if (seatId) {
                // Handle single seat selection
                addSeat(seatId);
            } else if (seatIdArray) {
                // Handle multiple seat selection
                seatIdArray.forEach(addSeat);
            }
            return newSelected;
        });
    };

    // GA sections use this to get their color
    const getSectionFillColor = (section) => {
        if (
            selectedSeatIds &&
            selectedSeatIds.gaSeats &&
            selectedSeatIds.gaSeats.hasOwnProperty(section.sectionId)
        ) {
            return SELECTED_COLOR;
        }

        return section.fill;
    };

    // This is triggered for BOTH GA and seated:
    // ( since you can select seated by selecting the entire section )
    const handleSectionSelect = (section) => {
        if (section?.zoomable) {
            // Seated-section
            const seatIds = getAllSeatsInSeatedSection(
                section,
                data.rows,
                data.seats
            );
            selectRegularSeats(undefined, seatIds);
        } else {
            // GA-section
            openModal(section);
        }
    };

    // const selectGASeats = (sectionId, seats) => {
    //     setSelectedSeatIds((prevSelected) => {
    //         const newSelected = { ...prevSelected };
    //         if (!newSelected.gaSeats) {
    //             newSelected.gaSeats = {};
    //         }

    //         if (!newSelected.gaSeats[sectionId]) {
    //             newSelected.gaSeats[sectionId] = seats;
    //         }

    //         return newSelected;
    //     });
    // };

    const getAllSeatsInSeatedSection = (section, rows, seats) => {
        return Object.values(rows)
            .filter((row) => row.sectionId === section.sectionId)
            .flatMap((row) => row.seats)
            .filter((seatId) => {
                if (!seats[seatId]) return false;
                return true;
            });
    };

    const getSeatFillColor = (seatId) => {
        if (
            selectedSeatIds &&
            selectedSeatIds.seats &&
            selectedSeatIds.seats.hasOwnProperty(seatId)
        ) {
            return SELECTED_COLOR;
        }
    };


    {/* 
  
    Customize this file ( or the files above ) in any which way that suits your purposes
    Just DO-NOT customize anything lower than this 
    
  */}
    return (
        <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
            {/* Seatmap fills all available space, so its also behind the Navigation now */}
            <ActionsProvider>
                <SeatMap
                    data={data}
                    background={background}
                    // Handling clicks
                    selectRegularSeats={selectRegularSeats}
                    handleSectionSelect={handleSectionSelect}
                    // Fill Colors
                    getSectionFillColor={getSectionFillColor}
                    getSeatFillColor={getSeatFillColor}
                    // For inventory "bar"
                    displayInventoryInfo={false}
                    deriveInventoryInfoFrom={[]} // does nothing unless displayInventoryInfo={true}
                />
            </ActionsProvider>
        </div>
    );
}
