import React, { useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';
import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';

import { getPackage, updatePackageVisibility } from "../../utilities/api";
import { checkPermission } from '../../utilities/helpers';

import { Cards } from './Cards';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function PackageDashboardWrapper({ packageId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { updateIsPackageOnsale, updateIsPackagePublished, updatePackage, updateIsPackageSoldout, updateHasPackageEnded, hasPackageEnded } = useContext(PackageDetailsContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [isVisible, setIsVisible] = useState()

    const [eventPackage, setEventPackage] = useState()

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 4));

    }, [orgPermissions])

    useEffect(() => {
        showLoading();
        loadPackage();

        const socket = io(process.env.REACT_APP_AUTH);

        socket.on("connect", () => {
            socket.on(`newOrder:${packageId}`, (data) => {
                loadPackage()
            })
        });
    }, [])

    const loadPackage = () => {
        getPackage(packageId)
            .then((res) => {
                setEventPackage(res.data)
                setIsVisible(res.data?.isVisible)
                // update context 
                updateIsPackageOnsale()
                updateHasPackageEnded()
                updateIsPackageSoldout(res.data)
                updateIsPackagePublished()
                hideLoading();
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    const handleChange = (_, isVisible) => {
        console.log('handle change', isVisible);
        setIsVisible(isVisible)
        // update context with changed isVisible 
        updatePackage({ ...eventPackage, isVisible })
        // update isVisible in db
        updatePackageVisibility({
            data: {
                id: eventPackage?.uuid,
                isVisible
            }
        })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <div className="position-relative">
                        <div className={`max-width-wrapper ${!hasPermission ? 'overlay' : ''}`}>
                            <header className="section-header section-heading">
                                <h1>Dashboard</h1>
                            </header>
                            <Cards eventPackage={eventPackage} isVisible={isVisible} handleChange={handleChange} isDisabled={hasPackageEnded} />
                        </div>
                        {!hasPermission && (
                            <NoPermissionsContainer />
                        )}
                    </div>
                </>
            )}
        </>
    );
}
