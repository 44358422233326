import React, { useLayoutEffect, useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import isEqual from "lodash/isEqual";

import LoadingContext from "../../context/Loading/Loading";
import AuthService from "../../utilities/services/auth.service";
import UserContext from "../../context/User/User";
import PackageDetailsContext from "../../context/PackageDetails/PackageDetails";

import { useFromPage } from "../../providers/FromPageProvider";
import { AssignToFanProvider } from "./AssignToFanProvider/AssignToFanModalProvider";

import { getPackage, updateSeatsInPackage } from "../../utilities/api";

import {
    fullHeightContainer,
    removeFullHeightContainer,
} from "../../utilities/helpers";

import { PageLoadingContainer } from "../PageLoadingContainer";
import { PackageMap } from "./PackageMap";
import { Navigation } from "./Navigation";
import { Panel } from "./Panel";
import { GAModalProvider } from "./GAModalProvider/GAModalProvider";

export default function PackageSeatmapWrapper({ uuid }) {
    const navigate = useNavigate();
    const location = useLocation();

    const { isAssigningToFan } = useFromPage();

    const organization = AuthService.getOrg()[0];

    const { orgPermissions } = useContext(UserContext);

    const { getPermissions } = AuthService;

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext);

    const { updatePackage, updateCanPublish, isPackagePublished, hasPackageEnded } = useContext(PackageDetailsContext)

    const { selectedFanSeats, setSelectedFanSeats } = useContext(
        PackageDetailsContext
    );

    const [eventPackage, setEventPackage] = useState();

    const [data, setData] = useState(null);

    const [background, setBackground] = useState(null);

    // Might not be so important for packagesMap but previously the ds for this was like:
    // { seats: {seatIds}, gaSeats: { sectionId: [seatIds]}}
    const [selectedSeatIds, setSelectedSeatIds] = useState({});

    // set initial seats to check whether button should be disabled
    const [initialState, setInitialState] = useState();

    const [isSaving, setIsSaving] = useState(false);

    // set html and body to full height
    useLayoutEffect(() => {
        ["html", "body"].forEach((el) =>
            document.querySelector(el).classList.add("full-height")
        );

        return () => {
            ["html", "body"].forEach((el) =>
                document.querySelector(el).classList.remove("full-height")
            );
        };
    }, [location.pathname]);

    useLayoutEffect(() => {
        // hide navbar
        const nav = document.getElementById("navbar");
        nav.style.display = "none";
        // hide event banner
        const banner = document.getElementById("banner");
        banner.style.display = "none";
        // hide sidebar
        const sidebarNav = document.getElementById("sidebarMenu");
        sidebarNav.style.display = "none";

        // modify container
        const container = document.getElementById("main-container");
        fullHeightContainer(container);

        // modify container
        const content = document.getElementById("main-content");
        content.classList.add("pb-0");
        content.classList.add("full-height-wrapper");
        content.classList.remove("spacer-md", "spacer-md--with-banner");

        return () => {
            nav.style.display = "";
            banner.style.display = "";
            sidebarNav.style.display = "";
            removeFullHeightContainer(container);
            content.classList.remove("pb-0");
            content.classList.remove("full-height-wrapper");
            content.classList.add("spacer-md", "spacer-md--with-banner");
        };
    }, []);

    const loadPackage = () => {
        getPackage(uuid)
            .then((res) => {
                setEventPackage(res?.data);
                setData(res?.data.seatmap?.mapping);
                setBackground(res?.data.seatmap?.background);
                // Dont load seats from package if on assign to fan seatmap
                if (!isAssigningToFan) {
                    setSelectedSeatIds(res?.data.seats);
                    console.log("set initial seats");
                    // Deep copy to prevent reference issues
                    setInitialState(JSON.parse(JSON.stringify(res?.data?.seats)));
                }
            })
            .catch((err) => console.error(err))
            .finally(() => hideLoading());
    };

    useEffect(() => {
        showLoading();
        loadPackage();
    }, []);

    const saveSeatSelections = () => {
        console.log("saveSeatSelections....")
        let dataPack = {
            id: eventPackage.id,
            seatsInPackage: selectedSeatIds,
            mapping: data,
        };
        // Since we're sharing the same seatmap for different purposes change the action based on route
        if (isAssigningToFan) {
            setSelectedFanSeats(selectedSeatIds);
        } else {
            setIsSaving(true);
            console.log("updateSeatsInPackage.... FE")
            updateSeatsInPackage(dataPack).then((res) => {
                setIsSaving(false);
                updatePackage(res?.data)
                updateCanPublish(res?.data?.name, res?.data?.seats)
                if (!isSaving) {
                    navigate(`/mypackage/${uuid}/assign-packages`);
                }
            });
        }
    };

    const handleGoBack = () => {
        navigate("..");
    };

    const clearSelectedSeats = () => {
        setSelectedSeatIds({});
    };

    const checkDisabled = () => {
        return isEqual(initialState, selectedSeatIds);
    };

    useEffect(() => {
        setSelectedFanSeats(selectedSeatIds);
    }, [selectedSeatIds]);

    useEffect(() => {
        console.log("selectedSeatIds: ", selectedSeatIds)
    }, [selectedSeatIds])

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer style='without-sidebar' />
            ) : (
                <>
                    <AssignToFanProvider>
                        <Navigation
                            eventPackage={eventPackage}
                            hasPackageEnded={hasPackageEnded}
                            isPackagePublished={isPackagePublished}
                            selectedSeatIds={selectedSeatIds}
                            saveSeatSelections={saveSeatSelections}
                            handleGoBack={handleGoBack}
                            isDisabled={checkDisabled()}
                            isSaving={isSaving}
                        />
                        {isAssigningToFan && (
                            <>
                                {selectedSeatIds && Object.keys(selectedSeatIds).length > 0 && (
                                    <Panel
                                        selectedSeatIds={selectedSeatIds}
                                        handleRemove={clearSelectedSeats}
                                    />
                                )}
                            </>
                        )}
                        <GAModalProvider setSelectedSeatIds={setSelectedSeatIds}>
                            {data && background && (
                                <PackageMap
                                    background={background}
                                    data={data}
                                    selectedSeatIds={selectedSeatIds}
                                    setSelectedSeatIds={setSelectedSeatIds}
                                />
                            )}
                        </GAModalProvider>
                    </AssignToFanProvider>
                </>
            )}
        </>
    );
}
