import { useLayoutEffect, useState } from "react";

import { generatePredeterminedColor } from "../../../../utilities/helpers";

import { ActionsProvider } from "./ActionsProvider/ActionsProvider";
import { useInventory } from "../../InventoryProvider/InventoryProvider";
import { Sidebar } from "./Sidebar";
import { SeatMap } from "./SeatMap";

import "./mapConfigurations.scss";

export default function MapConfigurations({
    isEventOnsale,
    hasEventEnded,
    isEventSoldout,
    data,
    background,
    activeTab,
    priceLevels,
    setPriceLevels,
    unassignedSeats,
    getDefaultObj,
    getObjectTotalSeatCount,
}) {
    const SELECTED_COLOR = "rgba(20, 20, 22, 1)";
    const {
        HOLDSCOLOR,
        KILLSCOLOR,
        offers,
        kills,
        holds,
        setHolds,
        setKills,
        sold,
        selectedSeatIds,
        setSelectedSeatIds,
        getSeatItem,
        getGAPLIDFrom,
        isSectionSelected,
        getGASectionColor,
    } = useInventory();

    const [selectedGASectionId, setSelectedGASectionId] = useState(null);
    const [showSelectGASeats, setShowSelectGASeats] = useState(false);

    // modify sidebar container when seats are selected
    useLayoutEffect(() => {
        const el = document.querySelector(".cef-seatmap-wrapper");
        if (
            (activeTab === "scaling" && Object.keys(selectedSeatIds).length > 0) ||
            (activeTab === "inventory" && Object.keys(selectedSeatIds).length > 0)
        ) {
            el.classList.add("panel-open");
        }

        return () => {
            el.classList.remove("panel-open");
        };
    }, [selectedSeatIds]);

    // add new pricing level, hold, or kill object
    const handleAdd = (type, id) => {
        const generateKey = (obj) => {
            const keys = Object.keys(obj);
            if (keys.length === 0) {
                return keys.length + 1;
            } else {
                // Get the highest existing key and add 1 to get the next key - so keys don't get messed up after deleting
                return Math.max(...keys) + 1;
            }
        };

        const createHoldKill = (prevState, id, color, name) => {
            const index = generateKey(prevState);

            // adding sub category
            if (id) {
                const catIndex = generateKey(prevState[id]?.categories);
                return {
                    ...prevState,
                    [id]: {
                        ...prevState[id],
                        categories: {
                            ...prevState[id].categories,
                            [catIndex]: {
                                ...getDefaultObj(catIndex, `Category ${catIndex}`, color),
                            },
                        },
                    },
                };
            } else {
                return {
                    ...prevState,
                    [index]: {
                        ...getDefaultObj(index, `${index} ${name}`, color),
                        categories: {},
                    },
                };
            }
        };

        switch (type) {
            case "level":
                const index = generateKey(priceLevels);
                setPriceLevels((prevState) => ({
                    ...prevState,
                    [index]: {
                        ...getDefaultObj(
                            index,
                            `P${index}`,
                            generatePredeterminedColor(index)
                        ),
                        price: 0,
                    },
                }));
                break;

            case "hold":
                setHolds((prevState) =>
                    createHoldKill(prevState, id, HOLDSCOLOR, "Hold")
                );
                break;

            case "kill":
                setKills((prevState) =>
                    createHoldKill(prevState, id, KILLSCOLOR, "Kill")
                );
                break;

            default:
                break;
        }
    };

    const selectUnassigned = () => {
        const unassignedObject = Object.values(unassignedSeats)[0];
        const unassignedIds = [];

        // Get all regular unassigned seats
        unassignedIds.push(...unassignedObject.seats);

        // Select regular unassigned seats
        selectRegularSeats(null, unassignedIds);

        if (unassignedObject.gaSeats) {
            const addToGASeats = {};

            for (const [sectionId, seats] of Object.entries(
                unassignedObject.gaSeats
            )) {
                addToGASeats[sectionId] = {
                    unassigned: seats,
                };
            }

            // Select GA unassigned seats
            selectGASeats(addToGASeats);
        }
    };

    // GA section is clicked, do something
    const clickGASection = (section) => {
        if (activeTab === "inventory") {
            clickOpenGAModal(section);
            return;
        }

        // If seats are currently in a PL return the id
        const sectionPLRes = getGAPLIDFrom(section.sectionId);
        // Also check if section is already selected, if so return

        const fromIdKey = sectionPLRes || "unassigned";
        const addToGASeats = {
            [section.sectionId]: {
                [fromIdKey]: section.spots,
            },
        };

        selectGASeats(addToGASeats);
    };

    const selectGASeats = (gaSeatsToSelect) => {
        setSelectedSeatIds((prevSelected) => {
            const newSelected = { ...prevSelected };
            if (!newSelected.gaSeats) {
                newSelected.gaSeats = {};
            }

            for (const [sectionId, fromSeats] of Object.entries(gaSeatsToSelect)) {
                // Add object for sectionId if does not exist
                if (!newSelected.gaSeats[sectionId]) {
                    newSelected.gaSeats[sectionId] = {};
                }

                for (const [fromId, seats] of Object.entries(fromSeats)) {
                    if (!newSelected.gaSeats[sectionId][fromId]) {
                        newSelected.gaSeats[sectionId][fromId] = [];
                    }

                    // Filter out seats that already exist before pushing
                    const existingSeats = new Set(newSelected.gaSeats[sectionId][fromId]);
                    const uniqueNewSeats = seats.filter(
                        (seat) => !existingSeats.has(seat)
                    );

                    newSelected.gaSeats[sectionId][fromId].push(...uniqueNewSeats);
                }
            }

            return newSelected;
        });
    };

    // save regular seated seats,,,
    const selectRegularSeats = (seatId, seatIdArray) => {
        setSelectedSeatIds((prevSelected) => {
            const newSelected = { ...prevSelected };
            if (!newSelected.seats) {
                newSelected.seats = {};
            }

            // getSeatItem is not getting the correct state for some reason...
            const addSeat = (id) => {
                const seatItem = getSeatItem(id);
                console.log("seatItem: ", seatItem);
                const key = seatItem && seatItem.key ? seatItem.key : "unassigned";

                if (!(seatItem?.name?.toLowerCase() === "sold")) {
                    if (!newSelected.seats[key]) {
                        newSelected.seats[key] = [];
                    }
                    if (!newSelected.seats[key].includes(id)) {
                        newSelected.seats[key].push(id);
                    }
                }
            };

            if (seatId) {
                // Handle single seat selection
                addSeat(seatId);
            } else if (seatIdArray) {
                // Handle multiple seat selection
                seatIdArray.forEach(addSeat);
            }

            return newSelected;
        });
    };

    const clickOpenGAModal = (section) => {
        setSelectedGASectionId(section?.sectionId);
        setShowSelectGASeats(true);
    };

    const getSeatIdsForZoomableSection = (section, rows, seats) => {
        return Object.values(rows)
            .filter((row) => row.sectionId === section.sectionId)
            .flatMap((row) => row.seats)
            .filter((seatId) => {
                if (!seats[seatId]) return false;
                // dont include sold seats
                if (
                    getSeatItem(seatId) &&
                    getSeatItem(seatId)?.sold
                ) {
                    return false;
                }
                return true;
            });
    };

    const getSectionFillColor = (section) => {
        if (isSectionSelected(section.sectionId)) {
            return SELECTED_COLOR;
        }
        // Check if it's a GA section and get its color
        const gaColor = getGASectionColor(section.sectionId);
        if (gaColor && activeTab === "scaling") {
            return gaColor;
        }

        return section.fill;
    };

    const getSeatFillColor = (seatId) => {
        const seatItem = getSeatItem(seatId);
        return seatItem?.color;
    }

    const handleSectionSelect = (section) => {
        // Handle GA and seated differently
        if (section?.zoomable) {
            // seated
            const seatIds = getSeatIdsForZoomableSection(
                section,
                data.rows,
                data.seats
            );
            selectRegularSeats(undefined, seatIds);
        } else {
            clickGASection(data.sections[section.sectionId]);
        }
    };

    return (
        <>
            <Sidebar
                tab={activeTab}
                handleAdd={handleAdd}
                isEventOnsale={isEventOnsale}
                hasEventEnded={hasEventEnded}
                isEventSoldout={isEventSoldout}
                showSelectGASeats={showSelectGASeats}
                setShowSelectGASeats={setShowSelectGASeats}
                selectedGASectionId={selectedGASectionId}
                selectUnassigned={selectUnassigned}
                selectGASeats={selectGASeats}
                getObjectTotalSeatCount={getObjectTotalSeatCount}
            />
            {/* css wrapper for positioning, improves re-usability of actionsProvider + seatMap */}
            <div className='cef-seatmap-wrapper'>
                <ActionsProvider>
                    <SeatMap
                        data={data}
                        background={background}
                        // Handling clicks
                        selectRegularSeats={selectRegularSeats}
                        handleSectionSelect={handleSectionSelect}
                        // Fill Colors
                        getSectionFillColor={getSectionFillColor}
                        getSeatFillColor={getSeatFillColor}
                        // For inventory "bar"
                        displayInventoryInfo={activeTab === "inventory"}
                        deriveInventoryInfoFrom={[
                            { inventory: offers, type: "offers" },
                            { inventory: kills, type: "kills" },
                            { inventory: holds, type: "holds" },
                            { inventory: sold, type: "sold" },
                        ]}
                    />
                </ActionsProvider>
            </div>
        </>
    );
}
