import React from 'react';
import { useNavigate } from 'react-router-dom';

import { formatCurrency, formatPhoneNumber } from '../../../../../utilities/helpers';

import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';

import { EditDeleteDropdown } from '../../../../EditDeleteDropdown';

export default function FanInfo({ status, invoice, hasPackageEnded }) {
    const navigate = useNavigate()

    const copyInvoiceLink = (invoice) => {
        // Alert the copied text
        alert("Copied invoice link");
        return navigator.clipboard.writeText(invoice?.stripeInvoiceLink);
    }

    const hideButton = (invoice) => {
        if (invoice.status !== 'paid') return true
        return false
    }

    const fullName = `${invoice?.firstName} ${invoice?.lastName}`

    return (
        <div className='flex-row light' role="rowgroup">
            <div className='list-table-col text-truncate text-center lg' role="cell">
                <span>{fullName}</span>
            </div>
            <div className="list-table-col text-center lg" role="cell">
                <div className="text-truncate">
                    <span>{formatPhoneNumber(invoice?.phoneNumber)}</span>
                </div>
            </div>
            <div className="list-table-col" role="cell">
                <span>{invoice?.email}</span>
            </div>
            <div className="list-table-col sm" role="cell">
                <span>{formatCurrency(invoice?.totalAmount)}</span>
            </div>
            <div className="list-table-col sm" role="cell">
                <span>{formatCurrency(invoice?.amountDue)}</span>
            </div>
            <div className="list-table-col p-0 sm d-flex" role="cell">
                <Badge bg="none" className={`m-auto w-max-content badge--${status?.value}`}>{status?.label}</Badge>
            </div>
            <span className="btn-more-col btn-more-col--with-border" role="cell">
                <EditDeleteDropdown canEdit={false} canDelete={false}>
                    {(hideButton(invoice) || hasPackageEnded) &&
                        <li className='w-100'>
                            <Dropdown.Item as="button" className="btn-reload">Release tickets</Dropdown.Item>
                        </li>
                    }
                    <li className='w-100'>
                        <Dropdown.Item as="button" className="btn-copy" onClick={() => copyInvoiceLink(invoice)} disabled={!invoice?.stripeInvoiceLink}>Copy invoice link</Dropdown.Item>
                    </li>
                    <li className='w-100'>
                        <Dropdown.Item as="button" className="btn-user" onClick={() => navigate(`fan/${invoice?.uuid}/profile`)}>View Fan Profile</Dropdown.Item>
                    </li>
                </EditDeleteDropdown>
            </span>
        </div>
    );
}
