import React from 'react';

import { formatCurrency, paymentStatuses } from '../../../../../utilities/helpers';

import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';

import { EditDeleteDropdown } from '../../../../EditDeleteDropdown';

export default function Payment({ invoice, handleShow }) {

    return (
        <div className='flex-row light flex-row--no-borders' role="rowgroup">
            <div className='list-table-col text-truncate' role="cell">
                <span>Jan 1, 2023</span>
            </div>
            <div className='list-table-col text-left text-truncate' role="cell">
                <span>{formatCurrency(2500)}</span>
            </div>
            <div className="list-table-col p-0 d-flex sm" role="cell">
                <Badge bg="none" className={`m-auto w-max-content badge--paid`}>{paymentStatuses['paid']?.label}</Badge>
            </div>
            <span className="btn-more-col pe-2" role="cell">
                <EditDeleteDropdown canEdit={false} canDelete={false}>
                    {/* <li className='w-100'>
                        <Dropdown.Item as="button" onClick={handleShow} className="btn-checkbox">Mark as paid</Dropdown.Item>
                    </li> */}
                    <li className='w-100'>
                        <Dropdown.Item as="button" className="btn-receipt">View receipt</Dropdown.Item>
                    </li>
                    <li className='w-100'>
                        <Dropdown.Item as="button" className="btn-receipt" disabled={!invoice?.stripeInvoiceLink}>View invoice</Dropdown.Item>
                    </li>
                </EditDeleteDropdown>
            </span>
        </div>
    );
}
