import React from 'react';
import { Link } from 'react-router-dom';

import { formatPhoneNumber } from '../../../../../utilities/helpers';

export default function HolderInfo({ holder }) {

    return (
        <div className='flex-row light' role="rowgroup">
            <div className='list-table-col text-truncate text-center lg' role="cell">
                <span>{holder?.firstName}</span>
            </div>
            <div className='list-table-col text-truncate text-center lg' role="cell">
                <span>{holder?.lastName}</span>
            </div>
            <div className="list-table-col text-center lg" role="cell">
                <div className="text-truncate">
                    <span>{formatPhoneNumber(holder?.phoneNumber)}</span>
                </div>
            </div>
            <div className="list-table-col" role="cell">
                <span>{holder?.email}</span>
            </div>
            <div className="list-table-col md text-center" role="cell">
                <Link to={`fan/${holder?.uuid}/profile`}>View</Link>
            </div>

        </div>
    );
}
