import React, { useState, useEffect, useRef } from "react";

import { getAllEventStats } from '../../../utilities/api';

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { SearchBar } from '../../SearchBar';
import { Event } from "./Event";
import { LoadingContainer } from '../../LoadingContainer'
import { Spinner } from '../../LoadingContainer/Spinner';

import './eventsList.scss';

export default function EventsList() {

    const prevEventStatus = useRef('published');

    const paginateRef = useRef(0);

    const COUNT_PER_PAGE = 5;

    const eventStatusOpt = [
        {
            label: 'Published',
            value: 'published'
        },
        {
            label: 'Draft',
            value: 'draft'
        },
        {
            label: 'Past',
            value: 'past'
        }
    ]

    // set when searching or switching event statuses - show spinner 
    const [isLoading, setIsLoading] = useState(false);

    // fetching more events 
    const [isDataLoading, setIsDataLoading] = useState(false);

    const [events, setEvents] = useState([])

    const [eventStatus, setEventStatus] = useState('published')

    const [start, setStart] = useState(0)

    // flag whether all events are displayed
    const [isAllEvents, setIsAllEvents] = useState(false)

    // search query
    const [
        query,
        setQuery
    ] = useState('');

    // switching event statuses
    useEffect(() => {
        reset()
    }, [eventStatus])

    // searching for event
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Make API request with the current value
            if (query) {
                reset()
            }
        }, 1000);

        // no query reset events 
        if (!query) reset()

        return () => clearTimeout(delayDebounceFn);
    }, [query])

    const reset = () => {
        // reset pagination start to 0 
        paginateRef.current = 0
        getEvents()
        setIsLoading(true)
    }

    const getEvents = () => {
        getAllEventStats(eventStatus, query, paginateRef.current)
            .then((res) => {
                // searching or switching event statuses or resetting pagination count 
                if (query || prevEventStatus.current !== eventStatus || paginateRef.current === 0) {
                    setEvents(res?.data)
                    setState(res?.data)
                } else {
                    // loading more, append new events to existing events
                    setEvents([...events, ...res?.data])
                    setState(res?.data)
                }
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
                setIsDataLoading(false)
            })
    }

    // shared actions
    const setState = (events) => {
        // if less than paginate count all events are already displayed
        if (events?.length < COUNT_PER_PAGE) {
            setIsAllEvents(true)
        } else {
            setIsAllEvents(false)
        }
        setIsLoading(false)
        setIsDataLoading(false)
        prevEventStatus.current = eventStatus
    }

    const getNext = () => {
        setIsDataLoading(true)
        paginateRef.current = paginateRef.current + COUNT_PER_PAGE
        getEvents()
    }

    const handleSelect = (val) => {
        setEventStatus(val)
    }

    return (
        <>
            <Stack direction='horizontal' gap={3} className="mb-4">
                <div className="flex-grow-1">
                    <SearchBar getQuery={setQuery} placeholder="Search by name" />
                </div>
                <FloatingLabel controlId="status" label="Event status">
                    <Form.Select name="status" value={eventStatus} onChange={(e) => handleSelect(e.target.value)}>
                        {eventStatusOpt.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </Form.Select>
                </FloatingLabel>
            </Stack>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    {events?.length > 0 ? (
                        <>
                            <div id="events-table" className="list-table four-col" role="table">
                                <div className="flex-row list-table-header" role="rowgroup">
                                    <div className='list-table-col list-table-col-header lg-2' role="columnheader">
                                        <span>Event</span>
                                    </div>
                                    <div className="list-table-col list-table-col-header" role="columnheader">
                                        <span>Tickets sold</span>
                                    </div>
                                    <div className="list-table-col list-table-col-header" role="columnheader">
                                        <span>Total sales</span>
                                    </div>
                                    {/* <div className="list-table-col list-table-col-header lg" role="columnheader">
                            <span>Secondary sold</span>
                        </div>
                        <div className="list-table-col list-table-col-header lg" role="columnheader">
                            <span>Royalties</span>
                        </div> */}
                                    <div className="list-table-col list-table-col-header text-center" role="columnheader">
                                        <span>Status</span>
                                    </div>
                                </div>
                                {events.map((event, index) => (
                                    <Event key={index} event={event} eventStatus={eventStatus} />
                                ))}
                            </div>
                            {(!isLoading && events?.length > 0 && !isAllEvents) && (
                                <Stack direction="horizontal" className='justify-content-center'>
                                    <Button
                                        variant="outline-light"
                                        className='btn-width btn-width-sm mt-3'
                                        disabled={isDataLoading}
                                        onClick={getNext}
                                    >
                                        {isDataLoading ? (
                                            <Spinner variant="dark" />
                                        ) :
                                            'Load more'
                                        }
                                    </Button>
                                </Stack>
                            )}
                        </>
                    ) : (
                        <>
                            <h1 className="fs-md text-center">No events to show</h1>
                            <p className="small text-center">Create events now and start selling tickets</p>
                        </>
                    )}
                </>
            )}
        </>
    )
}