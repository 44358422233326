import React, { useState, useEffect } from "react";
import moment from 'moment'

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { eventOrdersForReport } from '../../../utilities/api';
import { formatCurrency, formatNumber } from "../../../utilities/helpers";

import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Stack from "react-bootstrap/Stack";

import { SearchBar } from '../../SearchBar';
import { ExportBtn } from '../../ExportBtn';
import { OrdersTable } from "./OrdersTable";
import { Pagination } from "../../Pagination";
import { EmptyContainer } from "../../EmptyContainer";
import { LoadingContainer } from "../../LoadingContainer";

export default function CustomReport({ columns, events, selectedColumns, selectedTypes }) {

    const dateRangeOpts = [
        {
            label: "Today",
            value: 'today'
        },
        {
            label: "Last 7 days",
            value: '7_days'
        },
        {
            label: "Last 4 weeks",
            value: '4_weeks'
        },
        {
            label: "Last 3 months",
            value: '3_months'
        },
        {
            label: "Last 12 months",
            value: '12_months'
        },
        {
            label: "Month to date",
            value: 'monthly'
        },
        {
            label: "Quarter to date",
            value: 'quarterly'
        },
        {
            label: "Year to date",
            value: 'yearly'
        },
        {
            label: "All time",
            value: 'all'
        },
    ]

    const resultsPerPageOpts = [
        {
            label: "20",
            value: 20
        },
        {
            label: "50",
            value: 50
        },
        {
            label: "100",
            value: 100
        }
    ]

    const [dateRange, setDateRange] = useState(dateRangeOpts[0].value)

    const [startDate, setStartDate] = useState(moment())

    const [endDate, setEndDate] = useState(moment())

    const [focusedInput, setFocusedInput] = useState(null)

    const [query, setQuery] = useState('')

    const [details, setDetails] = useState()

    const [isLoading, setIsLoading] = useState(false)

    // pagination 
    const [start, setStart] = useState(0);
    const [perPage, setPerPage] = useState(resultsPerPageOpts[0].value)
    const [currentOrders, setCurrentOrders] = useState()
    const [pageCount, setPageCount] = useState()

    const getReportOrders = () => {
        setIsLoading(true)
        eventOrdersForReport(events, startDate.format(), endDate.format())
            .then((res) => {
                setDetails(res.data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }

    // get specific order/s 
    useEffect(() => {
        getReportOrders()
    }, [events, endDate])

    useEffect(() => {
        const end = start + perPage;
        setCurrentOrders(details?.orders?.slice(start, end))
        setPageCount(Math.ceil(details?.orders?.length / perPage))
    }, [details, start, perPage])

    const handleDates = (start, end) => {
        setStartDate(start)
        setEndDate(end)
    }

    const handleRange = e => {
        const { value } = e.target;
        let startDate;
        let endDate;
        setDateRange(value)
        switch (value) {
            case 'today':
                startDate = moment()
                endDate = moment()
                break;
            // last 7 days
            case '7_days':
                startDate = moment().subtract(7, 'days')
                endDate = moment()
                break;

            // last 4 weeks
            case '4_weeks':
                startDate = moment().subtract(4, 'weeks')
                endDate = moment().subtract(4, 'weeks').add(4, 'weeks')
                break;

            // last 3 months
            case '3_months':
                startDate = moment().startOf('month').subtract(3, 'months')
                endDate = moment().startOf('month').subtract(3, 'months').add(3, 'months')
                break;

            // last 12 months
            case '12_months':
                startDate = moment().startOf('month').subtract(12, 'months')
                endDate = moment().startOf('month').subtract(12, 'months').add(12, 'months')
                break;

            // month to date
            case 'monthly':
                startDate = moment().startOf('month')
                endDate = moment()
                break;
            // quarter to date
            // case 'quarterly':
            //     start = 
            //     break; 

            // year to date
            case 'yearly':
                startDate = moment().startOf('year')
                endDate = moment()
                break;

            // case 'all':
            //     start = 
        }

        setStartDate(startDate)
        setEndDate(endDate)
    }

    const handleChange = (count) => {
        setPerPage(count)
        setStart(0)
    }

    // Invoke when user click to request another page.
    const handlePageClick = (e) => {
        const newStart = (e.selected * perPage) % details?.orders?.length;
        setStart(newStart);
    };


    return (
        <>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    {details?.orders?.length > 0 ? (
                        <>
                            <header className='section-header'>
                                <div className="actions-group-flex">
                                    <FloatingLabel controlId="dateRange" label="Date range">
                                        <Form.Select value={dateRange} onChange={handleRange} aria-label="Date Range">
                                            {dateRangeOpts.map((option, index) => (
                                                <option key={index} value={option.value}>{option.label}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                    {/* <DateRangePicker
                                        startDateId="startDate"
                                        endDateId="endDate"
                                        startDate={startDate}
                                        endDate={endDate}
                                        onDatesChange={({ startDate, endDate }) => { handleDates(startDate, endDate) }}
                                        focusedInput={focusedInput}
                                        onFocusChange={(focusedInput) => { setFocusedInput(focusedInput) }}
                                        isOutsideRange={() => false}
                                        hideKeyboardShortcutsPanel={true}
                                        daySize={32}
                                        keepOpenOnDateSelect={true}
                                        verticalSpacing={35}
                                    /> */}
                                    <SearchBar getQuery={setQuery} placeholder="Search by Order #, name, phone number or email" />
                                    <ExportBtn exportedData="report" />
                                </div>
                            </header>
                            <Stack direction="horizontal" className='mb-4 split-row'>
                                <Stack as="ul" direction="horizontal" className="horizontal-list">
                                    <li>
                                        Gross sales
                                        <span>{formatCurrency(details?.grossSales)}</span>
                                    </li>
                                    <li>
                                        Orders
                                        <span>{formatNumber(details?.count)}</span>
                                    </li>
                                    <li>
                                        Attendees
                                        <span>{formatNumber(details?.attendeesCount)}</span>
                                    </li>
                                </Stack>
                            </Stack>
                            <OrdersTable orders={currentOrders} columns={columns} selectedColumns={selectedColumns} selectedTypes={selectedTypes} />
                            <Stack direction="horizontal" className="mt-4 position-relative">
                                <Pagination pageCount={pageCount} handleClick={handlePageClick} />
                                {pageCount > 1 && (
                                    <Stack direction="horizontal" gap={3} id="results-per-page-container">
                                        <span className="fw-medium small flex-shrink-0">Results per page</span>
                                        <Form.Select value={perPage} onChange={(e) => handleChange(parseInt(e.target.value))} aria-label="Orders per page">
                                            {resultsPerPageOpts.map((option, index) => (
                                                <option key={index} value={option.value}>{option.label}</option>
                                            ))}
                                        </Form.Select>
                                    </Stack>
                                )}
                            </Stack>
                        </>
                    ) : (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>No orders for {events.length === 1 ? "this event." : "these events."} Choose one or more events to show data reports</p>
                            </EmptyContainer>
                        </Card>
                    )}
                </>
            )}
        </>
    )
}