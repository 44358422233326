import React, { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';

import { EmptyContainer } from '../../EmptyContainer';

export default function SelectColumnsCard({ id, columns, types, isDisabled, handleCheck, selectedColumns, selectedTypes, handleSelect, handleReport, savedReports, handleShowAdd, handleShowDelete }) {

    const [open, setOpen] = useState(false)

    return (
        <>
            <Card body className='card--sm'>
                <Button variant="default" className="btn-toggle w-100"
                    onClick={() => setOpen(!open)}
                    aria-controls="select-columns"
                    aria-expanded={open}
                >
                    <h1 className='card-body-title'>Configure columns</h1>
                </Button>
                <Collapse in={open}>
                    <div>
                        <div className="card-section">
                            <h2 className="heading mb-3">Saved reports</h2>
                            {savedReports?.length > 0 ? (
                                <Stack gap={3}>
                                    <p>Quickly access your saved reports</p>
                                    <Form.Check
                                        type="radio"
                                        id="none"
                                        className='mb-0'
                                        name="report"
                                        label="None"
                                        onChange={handleReport}
                                    />
                                    <Stack as="ul" gap={3}>
                                        {savedReports.map(report => (
                                            <Stack as="li" key={report.id} direction='horizontal' gap={3}>
                                                <Form.Check
                                                    id={report.id}
                                                    type="radio"
                                                    className='mb-0'
                                                    name="report"
                                                    checked={id === report.id}
                                                    onChange={(e) => handleReport(e, report.id)}
                                                    label={report.name}
                                                />
                                                <Button variant="link" onClick={(e) => handleShowAdd(e, report.id)}>Edit</Button>
                                                <Button variant="link" className='text-danger' onClick={(e) => handleShowDelete(e, report.id)}>Delete</Button>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            ) : (
                                <EmptyContainer><p>No saved reports</p></EmptyContainer>
                            )}
                            <p className='my-4'>Select the data you would like to output</p>
                            <Row className='gap-5'>
                                <Col lg={8}>
                                    <div className='split-row header'>
                                        <div className="heading--flex">
                                            <h2 className='heading'>Orders</h2>
                                            <Button
                                                variant='link'
                                                onClick={() => handleSelect('columns', 'all')}
                                                disabled={columns?.every(col => selectedColumns?.includes(col.id))}
                                                className="select-label">
                                                Select all
                                            </Button>
                                            <Button
                                                variant='link'
                                                onClick={() => handleSelect('columns')}
                                                disabled={selectedColumns?.length === 0}
                                                className="select-label">
                                                Deselect all
                                            </Button>
                                        </div>
                                    </div>
                                    <ul id='column-list'>
                                        <li>
                                            <ul>
                                                {columns.slice(0, 5)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(5, 10)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(10, 15)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(15, 20)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(20, 25)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(25)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </ul>
                                </Col>
                                <Col id="transaction-type-col">
                                    <div className='split-row'>
                                        <div className="heading--flex">
                                            <h2 className='normal'>Transaction type</h2>
                                            <Button
                                                variant='link'
                                                onClick={() => handleSelect('types', 'all')}
                                                disabled={types.every(type => selectedTypes.includes(type.value))}
                                                className="select-label">
                                                Select all
                                            </Button>
                                            <Button variant='link' className="select-label" disabled={selectedTypes?.length === 0} onClick={() => handleSelect('types')}>Deselect all</Button>
                                        </div>
                                    </div>
                                    <Stack as="ul">
                                        {types.map((type, index) => (
                                            <li key={index}>
                                                <Form.Check
                                                    label={type.label}
                                                    type="checkbox"
                                                    checked={selectedTypes.includes(type.value)}
                                                    id={type.value}
                                                    name="transaction_type"
                                                    onChange={(e) => handleCheck(e, 'types')}
                                                />
                                            </li>
                                        ))}
                                    </Stack>
                                </Col>
                            </Row>
                            <Stack direction="horizontal" className="mt-3 btn-group-flex justify-content-start">
                                <Button variant="outline-light" size="lg" disabled={isDisabled} onClick={handleShowAdd}>Save report</Button>
                            </Stack>
                        </div>
                    </div>
                </Collapse>
            </Card>
        </>
    );
}
