import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';

import { eventsForReport } from '../../utilities/api';
import { getTransactionTypes } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { SelectEventsCard } from './SelectEventsCard';
import { SelectColumnsCard } from './SelectColumnsCard';
import { CustomReport } from './CustomReport';
import { EmptyContainer } from '../EmptyContainer';
import { AddReportModal } from './AddReportModal';
import { DeleteModal } from "../DeleteModal";

import "./reportsWrapper.scss"

export default function ReportsWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const columns = [
        {
            idx: 1,
            label: 'Order #',
        },
        {
            idx: 2,
            label: 'Order date',
        },
        {
            idx: 3,
            label: 'Event name',
        },
        {
            idx: 4,
            label: 'First name',
        },
        {
            idx: 5,
            label: 'Last name',
        },
        {
            idx: 6,
            label: 'Email',
        },
        {
            idx: 7,
            label: 'Phone number',
        },
        {
            idx: 8,
            label: 'Country',
        },
        {
            idx: 9,
            label: 'Ticket quantity',
        },
        {
            idx: 10,
            label: 'Transaction type',
        },
        {
            idx: 11,
            label: 'Offer Type',
        },
        {
            idx: 12,
            label: 'Gross',
        },
        {
            idx: 13,
            label: 'Net',
        },
        {
            idx: 14,
            label: 'Service fees',
        },
        {
            idx: 15,
            label: 'Proccessing fee',
        },
        {
            idx: 16,
            label: 'Facility fees',
        },
        {
            idx: 17,
            label: 'Tax',
        },
        {
            idx: 18,
            label: 'Attendee status',
        },
        {
            idx: 19,
            label: 'Payment method',
        },
        {
            idx: 20,
            label: 'Last 4 digits'
        }
    ]

    const types = getTransactionTypes()

    const eventsOpts = [
        {
            label: "All events",
            value: "all"
        },
        {
            label: "Upcoming events",
            value: "upcoming"
        },
        {
            label: "Past events",
            value: "past"
        }
    ]

    const [initialEditState, setInitialEditState] = useState()

    const [events, setEvents] = useState([])

    const [showAdd, setShowAdd] = useState(false)

    const [showDelete, setShowDelete] = useState(false)

    const [savedReports, setSavedReports] = useState([]);

    const [report, setReport] = useState({
        name: ''
    });

    // selected report id on edit and delete
    const [id, setId] = useState();

    const [eventStatus, setEventStatus] = useState(eventsOpts[0].value)

    const [selectedEvents, setSelectedEvents] = useState([])

    const [selectedColumns, setSelectedColumns] = useState([])

    const [selectedTypes, setSelectedTypes] = useState([])

    const [isDisabled, setIsDisabled] = useState(false)

    const [isAddDisabled, setIsAddDisabled] = useState(false)

    const [isEventsLoading, setIsEventsLoading] = useState(false)

    const [isRemoving, setIsRemoving] = useState(false)

    // get all events 
    useEffect(() => {
        showLoading()
        loadEvents(eventStatus)
    }, [])

    // get filter events 
    useEffect(() => {
        setIsEventsLoading(true)
        loadEvents(eventStatus)
    }, [eventStatus])

    useEffect(() => {
        // columns or transactionTypes selected are same as current report columns
        setIsDisabled(selectedColumns?.length === 0 || (report?.columns?.every((i) => selectedColumns.includes(i)) && selectedColumns.length === report?.columns?.length) && (report?.transactionTypes?.every((i) => selectedTypes.includes(i)) && selectedTypes.length === report?.transactionTypes?.length))

        // when selected columns or selected types change, create new report 
        if ((report?.columns && !(report?.columns?.every((i) => selectedColumns.includes(i)) && selectedColumns.length === report?.columns?.length)) || (report?.transactionTypes && !(report?.transactionTypes?.every((i) => selectedTypes.includes(i)) && selectedTypes.length === report?.transactionTypes?.length))) {
            setReport();
        }
    }, [selectedTypes, selectedColumns, report?.columns])

    useEffect(() => {
        // if transaction type column is selected, select all types
        setSelectedTypes(selectedColumns.includes(13) ? [...types.map(type => type.value)] : [])

    }, [selectedColumns])


    useEffect(() => {
        setIsAddDisabled(initialEditState?.report?.name === report?.name || !report?.name)
    }, [initialEditState, report?.name])

    useEffect(() => {
        if (id) setInitialEditState({ report })
    }, [id])

    const loadEvents = (eventStatus) => {
        eventsForReport(eventStatus)
            .then((res) => {
                setEvents(res.data)
                setIsEventsLoading(false)
                hideLoading();
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
                setIsEventsLoading(false)
            })
    }

    const handleChange = e => {
        setReport({ ...report, [e.target.name]: e.target.value })
    }

    const reset = () => {
        // reset 
        setReport()
        setSelectedColumns([])
        setSelectedTypes([])
    }

    const handleShowAdd = (_, id) => {
        if (id) {
            handleReport(_, id)
            setId(id)
        }
        setShowAdd(true)
    }

    const handleCloseAdd = () => {
        setId()
        setInitialEditState()
        setShowAdd(false);
    }

    const handleShowDelete = (_, id) => {
        handleReport(_, id)
        setId(id)
        setShowDelete(true)
    }

    const handleCloseDelete = () => {
        setId()
        setShowDelete(false);
    }

    const handleReport = (_, id) => {
        if (id) {
            setId(id)
            const report = savedReports.find(report => report.id == id)
            setReport(report)
            setSelectedColumns(report.columns)
            setSelectedTypes(report.transactionTypes)
        } else {
            // reset 
            reset()
        }
    }

    const getState = flag => {
        let setter;
        let state;
        let data;

        switch (flag) {
            case 'events':
                data = events
                setter = setSelectedEvents
                state = selectedEvents
                break;
            case 'columns':
                data = columns
                setter = setSelectedColumns
                state = selectedColumns;
                break;
            case 'types':
                data = types
                setter = setSelectedTypes
                state = selectedTypes
                break;
        }
        return { setter, state, data }
    }

    const handleCheck = (e, flag) => {
        const { id, checked } = e.target;

        // get state objects
        const { setter, state, _ } = getState(flag)
        // set checkbox's id to state 
        setter([...state, Number.isInteger(Number(id)) ? Number(id) : id])

        if (!checked) {
            setter(state.filter(item => item != id));
        }
    }

    const handleSelect = (flag, action) => {
        const { setter, _, data } = getState(flag)

        // set state using either events, columns, transaction type state 
        if (action === 'all')
            setter([...data?.map(obj => obj?.idx || obj?.uuid || obj?.value)])

        else setter([])

    }

    // save report with selectedColumns 
    const handleSave = () => {
        handleCloseAdd()

        if (id) {
            // update name - use current report id  
        }

        // TODO: validate name to make it unique
        const reportData = {
            id: 1, // will change for each report saved to be able to check it on the UI 
            name: report.name,
            columns: selectedColumns,
            transactionTypes: selectedTypes
        }

        setSavedReports([...savedReports, reportData])
        setReport(reportData)
    }

    const handleDelete = () => {
        setIsRemoving(true)
        let updatedReports = savedReports.filter((report) => report.id !== id)
        setSavedReports(updatedReports)
        // reset 
        reset()
        handleCloseDelete()
        setIsRemoving(false)
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer style="without-sidenav" />
            ) : (
                <>
                    {events?.length > 0 ? (
                        <section id="custom-reports">
                            <header className='section-header'>
                                <div className="section-heading">
                                    <h1>Reports</h1>
                                </div>
                            </header>
                            <section>
                                <Stack gap={4}>
                                    {events?.length > 0 && (
                                        <SelectEventsCard isLoading={isEventsLoading} events={events} handleCheck={handleCheck} selected={selectedEvents} handleSelect={handleSelect} eventsOpts={eventsOpts} eventStatus={eventStatus} setEventStatus={setEventStatus} />
                                    )}
                                    {selectedEvents?.length > 0 && (
                                        <SelectColumnsCard id={report?.id} columns={columns} types={types} isDisabled={isDisabled} handleCheck={handleCheck} selectedColumns={selectedColumns} selectedTypes={selectedTypes} handleSelect={handleSelect} handleReport={handleReport} savedReports={savedReports} handleShowAdd={handleShowAdd} handleShowDelete={handleShowDelete} />
                                    )}
                                </Stack>
                            </section>
                            <section>
                                {selectedEvents.length > 0 && selectedColumns.length > 0 ? (
                                    <CustomReport columns={columns} events={selectedEvents} selectedColumns={selectedColumns} selectedTypes={selectedTypes} />
                                ) : (
                                    <>
                                        {selectedEvents.length === 0 && (
                                            <Card body>
                                                <EmptyContainer style="center lg">
                                                    <p>No event selected. Select one or more events to show data reports</p>
                                                </EmptyContainer>
                                            </Card>
                                        )}
                                        {selectedColumns.length === 0 && selectedEvents.length > 0 && (
                                            <Card body>
                                                <EmptyContainer style="center lg">
                                                    <p>Configure columns to show data on this table</p>
                                                </EmptyContainer>
                                            </Card>
                                        )}
                                    </>
                                )}
                            </section>

                        </section>
                    ) : (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>No event created. Create one or more events to show data reports</p>
                            </EmptyContainer>
                        </Card>
                    )}

                    <AddReportModal id={id} show={showAdd} handleClose={handleCloseAdd} name={report?.name} handleChange={handleChange} handleSave={handleSave} isDisabled={isAddDisabled} />

                    <DeleteModal show={showDelete} handleClose={handleCloseDelete} name={report?.name} isRemoving={isRemoving} handleDelete={handleDelete} />
                </>
            )}
        </>
    );
}
