import React from 'react'

import { paymentStatuses } from '../../../../utilities/helpers';

import { FanInfo } from './FanInfo'

export default function Fans({ packageId, invoices, hasPackageEnded }) {
    return (
        <div className="list-table list-table--with-inside-borders list-table--with-border list-table--with-bg list-table--with-radius four-col" role="table">
            <div className="flex-row list-table-header" role="rowgroup">
                <div className='list-table-col text-center list-table-col-header list-table-col-header--dark lg' role="columnheader">
                    <span>Ticket buyer</span>
                </div>
                <div className="list-table-col list-table-col-header list-table-col-header--dark lg" role="columnheader">
                    <span>Phone number</span>
                </div>
                <div className="list-table-col list-table-col-header list-table-col-header--dark" role="columnheader">
                    <span>Email</span>
                </div>
                <div className="list-table-col list-table-col-header list-table-col-header--dark sm" role="columnheader">
                    <span>Total Amount</span>
                </div>
                <div className="list-table-col list-table-col-header list-table-col-header--dark sm" role="columnheader">
                    <span>Amount Due</span>
                </div>
                <div className="list-table-col list-table-col-header sm text-center list-table-col-header--dark" role="columnheader">
                    <span>Status</span>
                </div>
            </div>
            {invoices && invoices?.map((invoice, idx) => (
                <FanInfo key={idx} invoice={invoice} packageId={packageId} status={paymentStatuses[invoice?.status]} hasPackageEnded={hasPackageEnded} />
            ))}
        </div>
    );
}