import React, { useState, useEffect, useRef } from "react";

import AuthService from '../../../utilities/services/auth.service';

import { getPackages, removePackage } from '../../../utilities/api';

import Stack from 'react-bootstrap/Stack';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

import { Package } from "./Package";
import { LoadingContainer } from '../../LoadingContainer'
import { DeleteModal } from "../../DeleteModal";

import './packagesList.scss';

export default function PackagesList() {

    const organization = AuthService.getOrg()[0];

    const [isLoading, setIsLoading] = useState(false)

    const [packages, setPackages] = useState([])

    const [expiredPackages, setExpiredPackages] = useState([])

    const [isRemoving, setIsRemoving] = useState(false)

    const [show, setShow] = useState(false)

    const [open, setOpen] = useState(false)

    // package id to delete
    const [id, setId] = useState()

    useEffect(() => {
        console.log('organization ', organization)
        loadPackages()
    }, [])

    const loadPackages = () => {
        setIsLoading(true)
        getPackages(organization?.uuid)
            .then(res => {
                setPackages(res?.data?.filter(evtPackage => evtPackage.status === 'published' || evtPackage?.status === 'on_sale'))
                setExpiredPackages(res?.data?.filter(evtPackage => evtPackage.status === 'complete'))
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoading(false))
    }

    const handleShow = (id) => {
        setShow(true)
        setId(id)
    }

    const handleClose = () => {
        setId()
        setShow(false)
    }

    const handleRemove = () => {
        setIsRemoving(true)
        removePackage(id).then(res => {
            console.log(res.data);
            handleClose()
            setIsRemoving(false)
            loadPackages()
        })
    }

    return (
        <>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    {(packages?.length > 0 || expiredPackages?.length > 0) ? (
                        <Stack gap={3}>
                            {packages?.length > 0 ? (
                                <Stack gap={2} as="ul" id="package-list">
                                    {packages?.map((eventPackage, idx) => (
                                        <Package key={idx} eventPackage={eventPackage} handleShow={handleShow} isDisabled={eventPackage?.status === 'complete'} />
                                    ))}
                                </Stack>
                            ) : (
                                <>
                                    <h1 className="fs-md text-center">No active packages to show</h1>
                                    <p className="small text-center">Create a package now and start selling tickets</p>
                                </>
                            )}
                            {expiredPackages?.length > 0 && (
                                <>
                                    <div className='expired-packages-container'>
                                        <div className='expired-packages'>
                                            <Button
                                                variant='default'
                                                className='mb-2 btn-toggle normal normal-bold w-100 px-0'
                                                onClick={() => setOpen(!open)}
                                                aria-controls='expired-packages-collapse'
                                                aria-expanded={open}
                                            >
                                                Expired Packages
                                            </Button>
                                            <Collapse in={open}>
                                                <div>
                                                    <Stack gap={2} as="ul" id="package-list">
                                                        {expiredPackages?.map((eventPackage, idx) => (
                                                            <Package key={idx} eventPackage={eventPackage} handleShow={handleShow} hasPackageEnded={eventPackage?.status === 'complete'} />
                                                        ))}
                                                    </Stack>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Stack>
                    ) : (
                        <>
                            <h1 className="fs-md text-center">No packages to show</h1>
                            <p className="small text-center">Create a package now and start selling tickets</p>
                        </>
                    )
                    }
                </>
            )}

            <DeleteModal entity="package" show={show} text={`All the remaining unsold seats that are assigned to this package will be deleted and will become available for single purchase.`} isRemoving={isRemoving} handleDelete={handleRemove} handleClose={handleClose} />
        </>
    )
}