import React, {
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

import { isValidPhoneNumber } from 'react-phone-number-input';

import PackageDetailsContext from "../../../context/PackageDetails/PackageDetails";

import { createInvoice } from "../../../utilities/api";

import { emailPatternMatch, formatCurrency, getFormattedTimezoneDate } from "../../../utilities/helpers";

import { AssignToFanModal } from "./AssignToFanModal";
import { ConfirmationModal } from './ConfirmationModal';

// Create the context
const AssignToFanContext = createContext(undefined);

export const useAssignToFanModal = () => {
    const context = useContext(AssignToFanContext);
    if (!context) {
        throw new Error("Context must be used within a useAssignToFanModal");
    }
    return context;
};

// Provide certain type of modals used in the page
export const AssignToFanProvider = ({ children }) => {

    const navigate = useNavigate()

    const { eventPackage, selectedFanSeats } = useContext(PackageDetailsContext)

    const [show, setShow] = useState(false);

    const [showConfirm, setShowConfirm] = useState(false)

    const [assignTo, setAssignTo] = useState()

    const [phoneNumber, setPhoneNumber] = useState('')

    const [isValidNumber, setIsValidNumber] = useState(true)

    const [isValidEmail, setIsValidEmail] = useState(true)

    // one-time or multiple
    const [paymentType, setPaymentType] = useState('one-time')

    // online or in-person
    const [paymentMethod, setPaymentMethod] = useState('online')

    // cash or check 
    const [paymentOption, setPaymentOption] = useState('cash')

    const [paymentPlans, setPaymentPlans] = useState({})

    const [initialState, setInitialState] = useState()

    const [isValid, setIsValid] = useState(false)

    const [isValidPlans, setIsValidPlans] = useState(true)

    const [isSaving, setIsSaving] = useState(false)

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [email, setEmail] = useState()

    const venueSeats = eventPackage ? eventPackage?.seatmap?.mapping?.seats : ''

    const fanSeats = (selectedFanSeats?.seats && venueSeats) ? Object.keys(selectedFanSeats?.seats).map(seat => venueSeats[seat]) : ''

    useEffect(() => {
        setInitialState({
            assignTo,
            paymentType: 'one-time',
            paymentOption: 'cash',
            paymentMethod: 'online',
            paymentPlans
        })
    }, [])

    useEffect(() => {
        const validAssignTo = (assignTo?.firstName && assignTo?.lastName && assignTo?.email && assignTo?.phoneNumber)

        const oneTime = paymentType === 'one-time'

        const validPaymentOption = oneTime ? paymentOption : (Object.keys(paymentPlans)?.length > 0 && Object.values(paymentPlans)?.every(plan => (plan?.date && plan?.amount)))

        setIsValid(validAssignTo && validPaymentOption && isValidNumber && isValidEmail)
    }, [assignTo, paymentType, paymentOption, paymentPlans, isValidNumber, isValidEmail])

    useEffect(() => {
        closeAlert()

        if (!isValidPlans) setIsValidPlans(true)
    }, [paymentType, paymentPlans])

    // save phone number to object every time it changes
    useEffect(() => {
        setAssignTo({ ...assignTo, phoneNumber })
    }, [phoneNumber])

    useEffect(() => {
        if (!isValidNumber) {
            setIsValidNumber(true)
        }
    }, [phoneNumber])

    useEffect(() => {
        if (!isValidEmail) {
            setIsValidEmail(true)
        }
    }, [assignTo?.email])

    useEffect(() => {
        // reset payment method everytime payment type (one-time/multiple) changes
        // reset back to online if not already
        if (paymentMethod !== 'online') {
            setPaymentMethod('online')
        }
        // reset plans everytime payment type changes
        if (paymentPlans && Object.keys(paymentPlans)?.length > 0) {
            setPaymentPlans({})
        }
    }, [paymentType])

    useEffect(() => {
        // reset payment option everytime payment method (cash/check) changes
        // reset back to cash if not already
        if (paymentOption !== 'cash') {
            setPaymentOption('cash')
        }
    }, [paymentMethod])

    const closeAlert = () => {
        setAlert({ show: false, variant: '', message: '' })
    }

    const validNumber = () => {
        return phoneNumber && isValidPhoneNumber(phoneNumber)
    }

    const handleValidNumber = (e) => {
        // check if valid 
        setIsValidNumber(validNumber(phoneNumber))
    }

    const handleValid = (e) => {
        if (e.target.value) {
            setIsValidEmail(emailPatternMatch(e.target.value))
        }
    }

    const handleClose = () => {
        setShow(false)
        setAssignTo(initialState?.assignTo)
        setPaymentOption(initialState?.paymentOption)
        setPaymentType(initialState?.paymentType)
        setPaymentPlans(initialState?.paymentPlans)
        setPaymentMethod(initialState?.paymentMethod)
        setPhoneNumber()
        setIsValid(true)
    }

    const handleShow = () => {
        setShow(true)
    }

    const handleCloseConfirm = () => {
        setShowConfirm(false)
        setEmail()
        navigate(`/mypackage/${eventPackage.uuid}/assign-packages`);
    }

    const handleSave = () => {
        let packageTotal = (parseFloat(eventPackage?.price) + parseFloat(eventPackage?.additionalTaxes ? eventPackage?.additionalTaxes : 0) + parseFloat(eventPackage?.additionalFees ? eventPackage?.additionalFees : 0)) * fanSeats.length

        const isValid = true

        let data = {}
        let invoice = {}

        const isMultiple = paymentType === 'multiple';

        data['packageUUID'] = eventPackage.uuid;

        invoice['assignTo'] = assignTo;
        invoice['paymentPlans'] = paymentPlans;
        invoice['paymentType'] = paymentType;
        invoice['paymentOption'] = isMultiple ? null : paymentOption;
        invoice['paymentMethod'] = isMultiple ? null : paymentMethod;

        invoice['selectedFanSeats'] = selectedFanSeats;
        invoice['fanSeats'] = fanSeats;

        data['invoice'] = invoice

        // validation - payment amounts have to add up to total amount due
        if (paymentType === 'multiple') {
            const total = Object.values(paymentPlans)?.reduce((acc, cur) => acc + parseFloat(cur?.amount || 0), 0)
            const doesAmountsMatchTotal = total == packageTotal
            if (!doesAmountsMatchTotal) {
                setAlert({
                    show: true,
                    variant: 'danger',
                    message: `Amounts must add up to total amount due: ${formatCurrency(packageTotal)}.`
                })
                setIsValid(false)
                setIsValidPlans(false)
                return
            }
        }

        if (isValid) {
            setIsSaving(true)

            // emails can be from users not currently in the sytem so all is needed is to check if the format is correct
            createInvoice(data)
                .then((res) => {
                    setIsSaving(false)
                    if (!isSaving) {
                        handleClose()
                        setShowConfirm(true)
                        setEmail(res?.data?.email)
                    }
                })
                .catch((err) => {
                    console.error(err)
                    setIsSaving(false)
                    setIsValid(false)
                })
        }
    }

    const handleChange = (e) => {
        if (e?.target) setAssignTo({ ...assignTo, [e.target.name]: e.target.value })
    }

    const handlePaymentType = (val) => {
        setPaymentType(val)
        setPaymentMethod('online')
    }

    const handleAddPlan = (e, total) => {
        let duration = Number(e.target.value)
        clearPlans(duration)
        let start = 1
        let paymentsSplit = parseFloat(total / duration).toFixed(2)
        let installments = {}

        while (start <= duration) {
            let anniversaryDate = start - 1
            installments[start] = { id: start, amount: paymentsSplit, date: new Date(moment('7:00 am', 'h:mm a').startOf('day').add(anniversaryDate, 'months').format()) }
            start++;
        }

        setPaymentPlans({
            ...paymentPlans,
            ...installments
        })
    }
    // Clears existing plans if out of duration range
    const clearPlans = (duration) => {
        let paymentPlansCopy = paymentPlans
        let keys = Object.keys(paymentPlans)

        for (let key of keys) {
            if (key > duration) delete paymentPlansCopy[key]
        }

        setPaymentPlans({
            ...paymentPlansCopy
        })
    }

    const handlePaymentPlan = (e) => {
        if (e?.target) {
            const { id, name, value } = e.target;
            setPaymentPlans({
                ...paymentPlans,
                [id]: {
                    ...paymentPlans[id],
                    [name]: value
                }
            })
        }
    }

    const setPaymentDate = (date, id) => {
        let paymentPlansCopy = paymentPlans
        let keys = Object.keys(paymentPlans)

        for (let key of keys) {
            paymentPlansCopy[key].date = new Date(moment(date.date).startOf('day').add((key - 1), 'months').format())
        }
        setPaymentPlans({ ...paymentPlansCopy })
    }

    return (
        <AssignToFanContext.Provider
            value={{
                show,
                handleClose,
                handleShow
            }}
        >
            <AssignToFanModal show={show} alert={alert} packageStart={new Date(getFormattedTimezoneDate(eventPackage?.start, eventPackage?.timezone))} assignTo={assignTo} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} handleValidNumber={handleValidNumber} isValidPhoneNumber={isValidNumber} paymentType={paymentType} handlePaymentType={handlePaymentType} paymentOption={paymentOption} setPaymentOption={setPaymentOption} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} handleChange={handleChange} paymentPlans={paymentPlans} handleAddPlan={handleAddPlan} handlePaymentPlan={handlePaymentPlan} setPaymentDate={setPaymentDate} isValid={isValid} handleValidEmail={handleValid} isValidEmail={isValidEmail} isValidPlans={isValidPlans} handleClose={handleClose} handleSave={handleSave} isSaving={isSaving} eventPackage={eventPackage} fanSeats={fanSeats} />
            {children}

            <ConfirmationModal show={showConfirm} email={email} choice={paymentType} opt={paymentOption} handleClose={handleCloseConfirm} />

        </AssignToFanContext.Provider>
    );
};
