import React, { useEffect, useState } from 'react';

import { formatDateTime, getTimezoneDate } from '../../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';

import { WarningCard } from '../../../WarningCard';

export default function OpenOffers({ offers, baseOffer, setBaseOffer, eventTimezone, getAvailabilityPeriods }) {

    const [updatedOffers, setUpdatedOffers] = useState()

    // change default periods in offer object 
    // get default periods from Standard Admission offer
    useEffect(() => {
        const offersExcludingStandard = offers.slice(1)?.map(offer => {
            return {
                ...offer,
                availability: getAvailabilityPeriods(offer)
            }
        })
        console.log(offers, offers.slice(0, 1));
        console.log(offersExcludingStandard);
        setUpdatedOffers([...offers.slice(0, 1), ...offersExcludingStandard])
    }, [offers])

    const getSaleDate = (offer, property, timezone, formatter) => {
        const selectedPeriod = offer?.availability.find(availability => availability.active)
        return formatDateTime(getTimezoneDate(selectedPeriod[property], timezone), formatter)
    }

    return (
        <>
            <div className="card-body-heading--sm card-body-heading--flex card-body-heading--flex-space-between">
                <div className='d-flex-column'>
                    <Card.Title as="h5" className='card-title-sm'>Existing Open Offers</Card.Title>
                    <Card.Subtitle as="h6" className="subtitle--dark">Select which open offer to apply this discount to</Card.Subtitle>
                </div>
            </div>
            <Stack as="ul">
                {updatedOffers?.map(offer => (
                    <Stack as="li" direction='horizontal' className='justify-content-between list-item list-item-xs list-item--without-bg' key={offer?.id}>
                        <div className='form-check--with-desc text-left'>
                            <Form.Check
                                name={offer?.name}
                                id={offer?.id}
                                type='radio'
                                label={offer?.name}
                                checked={offer.id == baseOffer?.id}
                                onChange={() => setBaseOffer(offer)}
                            />
                            <div className="mt-2">
                                <small className='subtitle'>
                                    <span>{getSaleDate(offer, 'starts', eventTimezone, 'dateOnly')}
                                        <span className='time'>{getSaleDate(offer, 'starts', eventTimezone, 'timeOnly')}</span></span><span className='to'>{getSaleDate(offer, 'ends', eventTimezone, 'dateOnly')}<span className='time'>{getSaleDate(offer, 'ends', eventTimezone, 'timeOnly')}</span>
                                    </span>
                                </small>
                            </div>
                        </div>
                    </Stack>
                ))}
            </Stack>
            <WarningCard margin="mt" text="This offer will be available for the whole duration of the selected offer it is discounted from" variant="primary" />
        </>
    );
}
