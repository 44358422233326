import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { SuccessContainer } from '../../../SuccessContainer'

export default function ConfirmationModal({ show, email, choice, opt, handleClose }) {

    return (
        <Modal centered animation={false} fullscreen="md-down" backdrop='static' show={show} onHide={handleClose} className='modal-sm'>
            <Modal.Body>
                <>
                    <SuccessContainer>
                        <h4 className="modal-body-heading-title">Package assigned to fan!</h4>
                    </SuccessContainer>
                    <p className="small">
                        {choice === 'one-time' && opt === 'in-person' ? (
                            <>
                                A copy of the invoice and receipt has been sent to{' '}
                                <span className="text-secondary">{email}</span>.
                            </>
                        ) : (
                            <>
                                A Stripe invoice link has been sent to{' '}
                                <span className="text-link text-link--thin">{email}</span>. You'll find the status and more information on the Assign Packages tab.
                            </>
                        )}
                    </p>
                    <Stack direction="horizontal" className="btn-group-flex btn-group-flex-justify">
                        <Button size="lg" onClick={handleClose}>Close</Button>
                    </Stack>
                </>
            </Modal.Body>
        </Modal>

    );
}
