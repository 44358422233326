import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom'

import AuthService from '../../utilities/services/auth.service';

import { capitalizeString } from '../../utilities/helpers';

import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Stack from 'react-bootstrap/Stack';

import desktopLogo from '../../assets/logo.svg';

import './navigation.scss';

export default function Navigation({ user }) {

    const navigate = useNavigate();

    const [org, setOrg] = useState()

    useEffect(() => {
        if (user) {
            let org = localStorage.getItem('org');
            if (org) setOrg(JSON.parse(org)[0])
        } else {
            setOrg('')
        }
    }, [user])

    const logout = () => {
        AuthService.logoutUser();
        navigate("/");
    }

    const getNameInitials = user => {
        if (user?.user) return capitalizeString(`${user?.user?.firstName.charAt(0)}${user?.user?.lastName.charAt(0)}`);
    }

    const displayName = (user) => {
        if (user?.user) return capitalizeString(`${user?.user?.firstName} ${user?.user?.lastName}`);
    }

    const getRole = (user) => {
        if (user?.user) {
            const member = org?.members.find(member => member.uuid === user?.user?.uuid)
            if (member?.superAdmin) {
                return 'Admin'
            }
        }
    }

    return (
        <div className="navigation position-sticky" id="navbar">
            <Navbar>
                <Container className='justify-content-start'>
                    <LinkContainer to="/" id="logo-link">
                        <Navbar.Brand>
                            <img src={desktopLogo} alt="Blocktickets logo" width="117" height="32" />
                        </Navbar.Brand>
                    </LinkContainer>
                    {AuthService.isLoggedIn() && org &&
                        <>
                            <Nav activeKey={window.location.pathname} className="py-0" as="nav">
                                <ul
                                    id="main"
                                    role="main-navigation"
                                    className="d-flex align-items-center">
                                    <li>
                                        <LinkContainer
                                            to="events/create"
                                            className="btn btn-primary  btn-plus icon-button icon-button-lg icon-button--white  text-white btn-sm">
                                            <Nav.Link>Create an event</Nav.Link>
                                        </LinkContainer>
                                    </li>
                                    <li>
                                        <LinkContainer to="/">
                                            <Nav.Link>Events</Nav.Link>
                                        </LinkContainer>
                                    </li>
                                    <li>
                                        <LinkContainer to="/reports">
                                            <Nav.Link>Reports</Nav.Link>
                                        </LinkContainer>
                                    </li>
                                    {/* Hide on production until ready
                                    {window.location.host !== 'admin.blocktickets.xyz' &&
                                        <li>
                                            <LinkContainer to="/marketing">
                                                <Nav.Link>Marketing</Nav.Link>
                                            </LinkContainer>
                                        </li>
                                    } */}
                                    {/* Hide on production until ready
                                    {window.location.host !== 'admin.blocktickets.xyz' &&
                                        <li>
                                            <LinkContainer to="/attendees">
                                                <Nav.Link>Check-in</Nav.Link>
                                            </LinkContainer>
                                        </li>
                                    } */}
                                </ul>
                            </Nav>
                            <Dropdown className='ms-auto' align="end">
                                <Dropdown.Toggle id="profile-dropdown">
                                    {getNameInitials(user)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='w-lg dropdown-menu-lg'>
                                    <Stack gap={1} className='mb-3'>
                                        <h1 className='normal normal-bold'>{org?.name}</h1>
                                        <Stack gap={1}>
                                            <p>{displayName(user)}</p>
                                            <span className='text-muted caption'>{getRole(user)}</span>
                                        </Stack>
                                    </Stack>
                                    <Stack as="ul" gap={2}>
                                        <li>
                                            <LinkContainer to="/settings">
                                                <Dropdown.Item >Settings</Dropdown.Item>
                                            </LinkContainer>
                                        </li>
                                        <li>
                                            <Dropdown.Item as="button" onClick={() => logout()}>Logout</Dropdown.Item>
                                        </li>
                                    </Stack>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    }
                </Container>
            </Navbar>
        </div>
    );
}
