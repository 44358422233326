import { createContext } from 'react';

const PackageDetailsContext = createContext({
    eventPackage: {},
    updatePackage: () => { },
    isGAOnlyPackage: false,
    setIsGAOnlyPackage: () => { },
    packageStart: null,
    storePackageStart: () => { },
    packageEnd: null,
    storePackageEnd: () => { },
    packageVisibility: null,
    storePackageVisibility: () => { },
    canPublish: false,
    updateCanPublish: () => { },
    isPackagePublished: false,
    updateIsPackagePublished: () => { },
    isPackageOnsale: false,
    updateIsPackageOnsale: () => { },
    isPackageSoldout: false,
    updateIsPackageSoldout: () => { },
    hasPackageEnded: false,
    updateHasPackageEnded: () => { },
    selectedFanSeats: {},
    setSelectedFanSeats: () => { }
});

export default PackageDetailsContext;