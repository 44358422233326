import { useEffect, useLayoutEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import EventDetailsContext from "../../../context/EventDetails/EventDetails";

import {
    fullHeightContainer,
    removeFullHeightContainer,
} from "../../../utilities/helpers";

import {
    scalingInventoryToEvent,
    assignSeatmapToEvent,
    resetMapState,
    createTicketGroups,
} from "../../../utilities/api";

import { useInventory } from "../InventoryProvider/InventoryProvider";

import { Navigation } from "./Navigation";
import { ResetMapModal } from "./ResetMapModal";
import { ResetConfigurationModal } from "./ResetConfigurationModal";
import { MapConfigurations } from "./MapConfigurations";
import { SelectMapModal } from "../SelectMapModal";
import { SaveModal } from "./SaveModal";
import { PageLoadingContainer } from "../../PageLoadingContainer";

export default function ConfigureMap({
    eventId,
    offerId,
    event,
    data,
    background,
    seatmaps,
    activeTab,
    setActiveTab,
    selectedMapId,
    configuration,
    isReloading,
    setIsReloading,
    reloadData,
}) {
    const location = useLocation();
    const navigate = useNavigate();

    const {
        isEventOnsale,
        isEventPublished,
        hasEventEnded,
        isEventSoldout,
        setStandardAdmissionOfferHasInventory
    } = useContext(EventDetailsContext);

    const {
        setHolds,
        setKills,
        holds,
        kills,
        offers,
        setOffers,
        priceLevels,
        setPriceLevels,
        unassignedSeats,
        setUnassignedSeats,
        setSold,
        SOLDCOLOR,
        openScalingIncompleteModal,
    } = useInventory();

    const [initialSelectedId, setInitialSelectedId] = useState();
    // local state of selectedMapId in case of reverting back to original value
    const [selectedId, setSelectedId] = useState(null);
    // array of all seats
    const [totalSeats, setTotalSeats] = useState({});

    const [showResetConfiguration, setShowResetConfiguration] = useState(false);

    const [showReset, setShowReset] = useState(false);

    const [choice, setChoice] = useState(null);

    const [show, setShow] = useState(false);

    const [showSave, setShowSave] = useState(false);

    const [saveStep, setSaveStep] = useState(1);

    const [isSaving, setIsSaving] = useState(false);

    const [isConfigSaved, setIsConfigSaved] = useState(false);
    const [loadedState, setLoadedState] = useState(false);

    // set html and body to full height
    useLayoutEffect(() => {
        ["html", "body"].forEach((el) =>
            document.querySelector(el).classList.add("full-height")
        );

        return () => {
            ["html", "body"].forEach((el) =>
                document.querySelector(el).classList.remove("full-height")
            );
        };
    }, [location.pathname]);

    useLayoutEffect(() => {
        // hide navbar
        const nav = document.getElementById("navbar");
        nav.style.display = "none";
        // hide event banner
        const banner = document.getElementById("banner");
        banner.style.display = "none";
        // hide sidebar
        const sidebarNav = document.getElementById("sidebarMenu");
        sidebarNav.style.display = "none";

        // modify sidebar container
        const container = document.getElementById("main-container");
        container.classList.add("sidebar-container-lg");
        fullHeightContainer(container);

        // modify container
        const content = document.getElementById("main-content");
        content.classList.add("pb-0");
        content.classList.add("full-height-wrapper");

        return () => {
            nav.style.display = "";
            banner.style.display = "";
            sidebarNav.style.display = "";
            container.classList.remove("sidebar-container-lg");
            removeFullHeightContainer(container);
            content.classList.remove("pb-0");
            content.classList.remove("full-height-wrapper");
        };
    }, []);

    useEffect(() => {
        if (offerId) setActiveTab("inventory");
    }, [offerId]);

    // set to selected map id
    useEffect(() => {
        const selectedId = selectedMapId || null;
        setSelectedId(selectedId);
        setInitialSelectedId(selectedId);
    }, [selectedMapId]);

    useEffect(() => {
        // save price levels/holds/kills/offers in session
        sessionStorage.setItem("priceLevels", JSON.stringify(priceLevels));
        sessionStorage.setItem("holds", JSON.stringify(holds));
        sessionStorage.setItem("kills", JSON.stringify(kills));
        setIsConfigSaved(true);
    }, [priceLevels, holds, kills]);

    useEffect(() => {
        // Set up an interval to update state every 3 seconds
        const intervalId = setInterval(() => {
            setIsConfigSaved(false);
        }, 3000); // 3000 milliseconds = 3 seconds

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    const getUnassignedSeats = (GASeatsBySection, regularSeats, priceLevels) => {
        const allPriceLevelSeats = Object.values(priceLevels).reduce(
            (acc, priceLevel) => {
                const { objectGASeatsBySection, regularSeats: plRegularSeats } =
                    getObjectAllSeats(priceLevel);

                // Merge regular seats
                acc.regularSeats.push(...plRegularSeats);

                // Merge GA seats by section
                Object.entries(objectGASeatsBySection).forEach(([sectionId, seats]) => {
                    if (!acc.GASeatsBySection[sectionId]) {
                        acc.GASeatsBySection[sectionId] = [];
                    }
                    acc.GASeatsBySection[sectionId].push(...seats);
                });

                return acc;
            },
            { GASeatsBySection: {}, regularSeats: [] }
        );

        // Find unassigned regular seats
        const unassignedRegularSeats = regularSeats.filter(
            (seat) => !allPriceLevelSeats.regularSeats.includes(seat)
        );

        // Find unassigned GA seats
        const unassignedGASeatsBySection = Object.entries(GASeatsBySection).reduce(
            (acc, [sectionId, seats]) => {
                const unassignedSeats = seats.filter(
                    (seat) =>
                        !allPriceLevelSeats.GASeatsBySection[sectionId]?.includes(seat)
                );
                if (unassignedSeats.length > 0) {
                    acc[sectionId] = unassignedSeats;
                }
                return acc;
            },
            {}
        );
        return {
            UnassignedGASeatsBySection: unassignedGASeatsBySection,
            UnassignedRegularSeats: unassignedRegularSeats,
        };
    };

    const getAllSeats = (mapping) => {
        const GASeatsBySection = {};
        const regularSeats = [];

        // Iterate through sections
        for (const [sectionId, section] of Object.entries(mapping.sections)) {
            if (!section.zoomable && section.spots) {
                GASeatsBySection[sectionId] = section.spots;
            }
        }

        // Add all seats to regularSeats
        if (mapping.seats) {
            regularSeats.push(...Object.keys(mapping.seats));
        }

        return { GASeatsBySection, regularSeats };
    };

    // PL's / inventories no longer have just one seats array
    // we also have GASeatsBySection
    // so this returns all seats
    const getObjectAllSeats = (object) => {
        const objectGASeatsBySection = {};
        const regularSeats = object?.seats ?? [];

        if (object?.gaSeats) {
            for (const [sectionId, seats] of Object.entries(object?.gaSeats)) {
                if (seats) {
                    objectGASeatsBySection[sectionId] = seats;
                }
            }
        }

        return { objectGASeatsBySection, regularSeats };
    };

    const getObjectTotalSeatCount = (object) => {
        if (!object) return 0;
        const { objectGASeatsBySection, regularSeats } = getObjectAllSeats(object);

        // Count regular seats
        const regularSeatCount = regularSeats.length;

        // Count GA seats
        const gaSeatCount = Object.values(objectGASeatsBySection).reduce(
            (total, sectionSeats) => total + sectionSeats.length,
            0
        );

        return regularSeatCount + gaSeatCount;
    };

    // object for all scaling/inventory
    const getDefaultObj = (
        id,
        name,
        color,
        regularSeats,
        GASeatsBySection,
        inventoryType
    ) => {
        const updatedSeats = regularSeats ?? [];
        const updatedGASeats = GASeatsBySection ?? {};
        return {
            id,
            name,
            color,
            seats: updatedSeats,
            gaSeats: updatedGASeats,
            inventoryType,
        };
    };

    const setUnassignedSeatsUtility = (GASeatsBySection, regularSeats) => {
        const unassignedSeatsObj = {
            unassigned: {
                ...getDefaultObj(
                    "unassigned",
                    "Unassigned",
                    "#e6e8ec",
                    regularSeats,
                    GASeatsBySection
                ),
            },
        };

        setUnassignedSeats(unassignedSeatsObj);
    };

    // get sold seats - are sold out and not a hold and not a kill
    const getSoldSeats = (event) => {
        const allSold = event?.tickets.filter(
            (ticket) => ticket?.on_sale_status === "sold"
        );

        const soldRegularSeats = allSold
            ?.filter((ticket) => ticket.generalAdmission === false)
            ?.map((ticket) => ticket.seatId);

        // GA Seats grouped by sectionId
        const soldGASeats = allSold
            ?.filter((ticket) => ticket.generalAdmission === true)
            ?.reduce((acc, ticket) => {
                // init arr for section
                if (!acc[ticket.sectionId]) {
                    acc[ticket.sectionId] = [];
                }

                acc[ticket.sectionId].push(ticket.seatId);
                return acc;
            }, {});

        return {
            soldRegularSeats: soldRegularSeats || [],
            soldGASeats: soldGASeats || {},
        };
    };

    const setSoldSeatsUtility = (event) => {
        const { soldRegularSeats, soldGASeats } = getSoldSeats(event);
        const soldSeatsObj = {
            1: {
                ...getDefaultObj(1, "Sold", SOLDCOLOR, soldRegularSeats, soldGASeats),
            },
        };

        setSold(soldSeatsObj);
        return { soldRegularSeats, soldGASeats };
    };

    const filterOutSoldSeats = (offer, soldRegularSeats, soldGASeats) => {
        const filteredSeats = offer.seats
            ? offer.seats.filter((seatId) => !soldRegularSeats.includes(seatId))
            : [];

        const filteredGASeats = {};
        if (offer.gaSeats) {
            Object.entries(offer.gaSeats).forEach(([sectionId, seats]) => {
                const soldSeatsForSection = soldGASeats[sectionId] || [];
                filteredGASeats[sectionId] = seats.filter(
                    (seatId) => !soldSeatsForSection.includes(seatId)
                );
            });
        }

        return {
            ...offer,
            seats: filteredSeats,
            gaSeats: filteredGASeats,
        };
    };

    // Stored mapState
    const setMapStateFromEvent = (
        pricingLevels,
        inventory,
        GASeatsBySection,
        regularSeats
    ) => {
        if (pricingLevels) setPriceLevels(pricingLevels);
        if (inventory?.kills) setKills(inventory.kills);
        if (inventory?.holds) setHolds(inventory.holds);
        const { soldRegularSeats, soldGASeats } = setSoldSeatsUtility(event);
        if (event?.offers) {
            const standardOffer = Object.values(event?.offers)[0];
            const id = standardOffer?.id || 1;

            const filteredStandardOffer = filterOutSoldSeats(standardOffer, soldRegularSeats, soldGASeats);

            const processedOffers = {
                [id]: {
                    ...getDefaultObj(
                        id,
                        "Open",
                        "#3ea9f7",
                        filteredStandardOffer?.seats,
                        filteredStandardOffer?.gaSeats,
                        "open"
                    ),
                    subOffers: [],
                },
            };

            // Add all remaining offers (including exclusive ones) directly
            event?.offers?.slice(1)?.forEach((offer) => {
                const filteredOffer = filterOutSoldSeats(offer, soldRegularSeats, soldGASeats);
                if (filteredOffer.inventoryType === "open") {
                    // Add to subOffers
                    processedOffers[id].subOffers.push({
                        ...filteredOffer,
                        color: "#3ea9f7",
                    });
                } else {
                    // Add as a separate offer (this includes exclusive offers)
                    processedOffers[filteredOffer.id] = {
                        ...filteredOffer,
                    };
                }
            });

            setOffers(processedOffers);
        }

        const { UnassignedGASeatsBySection, UnassignedRegularSeats } =
            getUnassignedSeats(GASeatsBySection, regularSeats, pricingLevels);

        setUnassignedSeatsUtility(
            UnassignedGASeatsBySection,
            UnassignedRegularSeats
        );
    };

    // Default state
    const setDefaultMapState = (GASeatsBySection, regularSeats) => {
        console.log("----");
        setUnassignedSeatsUtility(GASeatsBySection, regularSeats);
        // set open offer (Standard Admission etc.)
        const standardOffer = Object.values(event?.offers)[0];
        const id = standardOffer?.id || 1;
        const openDefaultOffer = getDefaultObj(
            id,
            "Open",
            "#3ea9f7",
            regularSeats,
            GASeatsBySection,
            "open" // inventoryType
        );
        setOffers({ [id]: openDefaultOffer });
    };

    // Boot-load initial state for map, either stored or manual
    useEffect(() => {
        if (!event || loadedState) return;
        setLoadedState(true); // so we never do this more than once

        const { pricingLevels, inventory, tickets, seatmap } = event;
        const { GASeatsBySection, regularSeats } = getAllSeats(seatmap.mapping);

        if (pricingLevels) {
            setMapStateFromEvent(
                pricingLevels,
                inventory,
                GASeatsBySection,
                regularSeats
            );
        } else {
            // AllSeats are same as unassignedSeats
            setDefaultMapState(GASeatsBySection, regularSeats);
        }

        setTotalSeats({ gaSeats: GASeatsBySection, seats: regularSeats });
    }, []);

    const resetConfiguration = async (eventWithNewConfig) => {
        setPriceLevels({});
        setHolds({});
        setKills({});

        // change tab to scaling
        setActiveTab("scaling");

        let gaSeats = totalSeats.gaSeats;
        let seats = totalSeats.seats;
        let event = eventWithNewConfig;
        console.log("new MAPPPING", event, event?.seatmap?.mapping);

        const { GASeatsBySection, regularSeats } = getAllSeats(
            event?.seatmap?.mapping
        );
        gaSeats = GASeatsBySection;
        seats = regularSeats;

        // reset offers and unassigned seats to totalSeats
        console.log("setUnassiged as: ", gaSeats, seats);
        setUnassignedSeatsUtility(gaSeats, seats);
        console.log("");
        const id = Object.values(event?.offers)[0]?.id || 1;
        console.log("-----");
        console.log("-----");
        console.log("resetConfig openOffer id: ", id);
        console.log("-----");
        console.log("-----");
        console.log("-----");

        const openDefaultOffer = getDefaultObj(
            id,
            "Open",
            "#3ea9f7",
            seats,
            gaSeats,
            "open"
        );
        console.log("OPENOFFERDEFAULT: ", openDefaultOffer);
        setOffers({ [id]: openDefaultOffer });
    };

    // reset configuration modal
    const handleShowResetConfiguration = () => setShowResetConfiguration(true);

    const handleCloseResetConfiguration = (_, choice) => {
        setShowResetConfiguration(false);
        // show select map modal
        if (choice) setShow(true);
    };

    // reset map configuration modal - reset or select map
    const handleClick = (e, choice) => {
        if (choice) {
            setChoice(choice);
            // close reset configuration modal and show select map modal
            handleCloseResetConfiguration(e, choice);
        } else {
            setShowResetConfiguration(false);
            // reset map
            setShowReset(true);
        }
    };

    // reset seatmap: reset configurations and reload event
    const resetMap = (eventUUID, handler) => {
        return new Promise((resolve, reject) => {
            let data = {
                eventUUID,
            };
            // reset - undo changes made to seatmap
            resetMapState(data)
                .then(() => {
                    setIsSaving(false);
                    handler();
                    // reload event and map data
                    reloadData().then((res) => {
                        resetConfiguration(res?.data); // reset data in localStorage and inventories
                        resolve();
                    });
                })
                .catch((err) => {
                    console.log("error in resetMapState: ", err);
                    reject(err);
                    setIsSaving(false);
                });
        });
    };

    // reset map modal
    const handleReset = () => {
        setIsSaving(true);
        resetMap(event?.uuid, handleCloseReset).catch((err) => {
            console.error("Error:", err);
            setIsSaving(false);
        });
    };

    const handleCloseReset = () => setShowReset(false);

    const handleSubmit = () => {
        setIsSaving(true);
        let data = {
            eventUUID: event.uuid,
            seatMapID: Number(selectedId),
        };
        // add selected seatmap to event
        assignSeatmapToEvent(data)
            .then(() => {
                resetMap(event?.uuid, handleClose)
                    .then(() => {
                        sessionStorage.setItem("isTemplate", JSON.stringify(false));
                    })
                    .catch((err) => {
                        console.error("Error resetting map:", err);
                    });
            })
            .catch((err) => {
                console.error(err);
                setIsSaving(false);
            });
    };

    const handleCancel = () => {
        setSelectedId(initialSelectedId);
        handleClose();
    };

    // select map modal
    const handleClose = () => {
        setChoice(null);
        setShow(false);
    };

    // save modal
    const handleShowSave = () => {
        // save template
        setSaveStep(2);
        setShowSave(true);
    };

    // get price levels from sessions to save to event
    const getPriceLevels = () => {
        let pricingLevels = sessionStorage.getItem("priceLevels");
        pricingLevels = JSON.parse(pricingLevels);
        return pricingLevels;
    };

    // Saves price levels/inventory to offers
    // assigns to Standard Admission offer
    // assigns to custom offer if offer id is passed
    const handleSaveToOffer = () => {
        return new Promise((resolve, reject) => {
            setIsSaving(true);
            // get data
            const offerData = {
                eventUUID: eventId,
                pricingLevels: getPriceLevels(),
                inventory: {
                    offers,
                    holds,
                    kills,
                },
                seatmapJSON: data,
            };
            // assign pricing levels and inventory
            console.log("calling SITE: ", offerData);
            scalingInventoryToEvent(offerData)
                .then(async () => {
                    console.log("SITE resolve");
                    console.log("BEFORE createTicketGroups")
                    let res = await createTicketGroups({ eventUUID: eventId });
                    console.log("createTicketGroupsss: ", res.data);
                    resolve();
                })
                .catch((err) => {
                    console.error("SITE: ", err);
                    reject();
                });
        });
    };

    const areAllSeatsAssignedToPriceLevels = () => {
        // all seats assigned to price levels
        const priceLevels = getPriceLevels();
        console.log(priceLevels);
        const priceLevelSeatCount = Object.values(priceLevels).reduce(
            (total, level) => {
                return total + getObjectTotalSeatCount(level);
            },
            0
        );

        // get all seats from seatmap
        let seats = [];

        seats.push({ seats: Object.keys(event?.seatmap?.mapping?.seats) });

        // get GA "seats"
        Object.values(event?.seatmap?.mapping?.sections).forEach((section) => {
            if (section?.spots) {
                seats.push({ seats: section?.spots });
            }
        });

        const totalSeats = seats?.flatMap((arr) => arr.seats);

        console.log("Price level seat count:", priceLevelSeatCount);
        console.log("Total seat count:", totalSeats);
        console.log(priceLevelSeatCount === totalSeats?.length);

        return priceLevelSeatCount === totalSeats?.length;
    };

    // when leaving seat map
    const handleLeave = () => {
        if (!areAllSeatsAssignedToPriceLevels()) {
            openScalingIncompleteModal();
            return;
        }

        setIsReloading(true);
        // save to offer
        handleSaveToOffer().then((res) => {
            setStandardAdmissionOfferHasInventory(true);
            navigate(`/myevent/${eventId}/offers`);
            setIsReloading(false);
        });
    };

    return (
        <>
            {isReloading ? (
                <PageLoadingContainer style='without-sidebar' size='sm' />
            ) : (
                <>
                    <Navigation
                        event={event}
                        isEventPublished={isEventPublished}
                        hasEventEnded={hasEventEnded}
                        isSaved={isConfigSaved}
                        configuration={configuration}
                        templateName={""}
                        unassignedSeats={Object.values(unassignedSeats)[0]}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        handleShowResetConfiguration={handleShowResetConfiguration}
                        handleShowSave={handleShowSave}
                        handleLeave={handleLeave}
                    />
                    <MapConfigurations
                        isEventOnsale={isEventOnsale}
                        hasEventEnded={hasEventEnded}
                        isEventSoldout={isEventSoldout}
                        data={data}
                        activeTab={activeTab}
                        priceLevels={priceLevels}
                        setPriceLevels={setPriceLevels}
                        unassignedSeats={unassignedSeats}
                        setUnassignedSeats={setUnassignedSeats}
                        setOffers={setOffers}
                        getDefaultObj={getDefaultObj}
                        getObjectTotalSeatCount={getObjectTotalSeatCount}
                        background={background}
                    />
                </>
            )}

            <ResetConfigurationModal
                show={showResetConfiguration}
                handleClose={handleCloseResetConfiguration}
                handleClick={handleClick}
            />

            <ResetMapModal
                isSaving={isSaving}
                show={showReset}
                handleReset={handleReset}
                handleClose={handleCloseReset}
            />

            <SelectMapModal
                show={show}
                choice={choice}
                maps={seatmaps}
                templates={[]}
                selected={selectedId}
                setSelected={setSelectedId}
                initialSelected={initialSelectedId}
                isTemplate={false}
                isSaving={isSaving}
                handleClose={handleClose}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
            />

            <SaveModal
                show={showSave}
                step={saveStep}
                setStep={setSaveStep}
                name={""}
                setName={() => console.log("no template")}
                isSaving={isSaving}
                handleClose={() => console.log("no submit")}
                handleCancel={() => console.log("no submit")}
                handleSubmit={() => console.log("no submit")}
            />
        </>
    );
}
