import React from 'react';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';

import { DateInputWrapper } from '../../../../DateInputWrapper';

export default function Plan({ plan, idx, packageStart, hasError, handlePaymentPlan, setPaymentDate }) {
    return (

        <Stack key={plan?.id} direction='horizontal' gap={4} as="li" className='align-items-center'>
            <Form.Label className='m-0 flex-shrink-0'>Payment {idx + 1}</Form.Label>
            <div className='flex'>
                <Form.Group>
                    <Form.Label className='form-label--lowercase fw-medium mb-2'>Amount</Form.Label>
                    <InputGroup className={`${hasError ? 'input-group-error' : ''}`}>
                        <InputGroup.Text id="amount">$</InputGroup.Text>
                        <Form.Control
                            placeholder="Amount"
                            id={plan?.id}
                            name='amount'
                            aria-describedby="amount"
                            pattern="^[0-9.]*$"
                            value={plan?.amount || ''}
                            onChange={(e) => handlePaymentPlan(e.target.value === '' || e.target.validity.valid ? e : plan?.amount)}
                            required
                            disabled={true}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Label className='form-label--lowercase fw-medium mb-2'>Due Date</Form.Label>
                    <DateInputWrapper
                        id="payment-start"
                        setDate={(date) => setPaymentDate({ date, id: plan?.id })}
                        selectedDate={plan?.date}
                        startDate={plan?.date}
                        maxDate={packageStart}
                        size="sm"
                        styles="form-control-height"
                        isReadOnly={idx > 0 ? true : false}
                    />
                </Form.Group>
            </div>
        </Stack>
    );
}
