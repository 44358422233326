import React, { createContext, useContext, useRef, useState } from 'react';

import { ZoomLevel } from './ZoomLevel';
import { ToolBar } from './ToolBar';

import '../SeatMap/seatMap.scss'

// Create the context
export const ActionsContext = createContext(undefined);

export const useActions = () => {
    const context = useContext(ActionsContext);
    if (!context) {
        throw new Error('Context must be used within a ActionsProvider');
    }
    return context;
}

// Create the provider component
export const ActionsProvider = ({ children }) => {
    const stageRef = useRef(null);
    const mapRef = useRef(null);
    const originalScaleRef = useRef(1);
    const [activeMapAction, setActiveMapAction] = useState(3);
    const [scale, setScale] = useState(1);
    const SCALE_FACTOR = 1.08; // Increased for more responsive feel
    const SCALE_LIMITS = { MIN: 100, MAX: 400 };

    const toolbarSelect = (action) => {
        if (activeMapAction === action) {
            setActiveMapAction(0);
            return;
        }

        setActiveMapAction(action);
    }

    function calculateScalePercentage(scaleToUse) {
        const ratio = scaleToUse / originalScaleRef.current;
        const percentage = ratio * 100;
        return percentage.toFixed(0);
    }

    return (
        <ActionsContext.Provider value={{ stageRef, scale, setScale, activeMapAction, calculateScalePercentage, originalScaleRef, SCALE_FACTOR, SCALE_LIMITS }}>
            <div id="map" ref={mapRef}>
                {children}
                <div className='controls-container'>
                    <ZoomLevel setScale={setScale} scale={scale} calculateScalePercentage={calculateScalePercentage} stageRef={stageRef} scaleFactor={SCALE_FACTOR} scaleLimits={SCALE_LIMITS} />
                </div>
                <ToolBar activeMapAction={activeMapAction} toolbarSelect={toolbarSelect} />
            </div>
        </ActionsContext.Provider>
    );
};