import React, { useEffect, useState, useRef } from "react";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { RequiredText } from "../../../RequiredText";
import { Level } from "./Level";
import { BreakdownModal } from "./BreakdownModal";
import { WarningCard } from "../../../WarningCard";

export default function PricingFees({
    opt,
    setOpt,
    discount,
    setDiscount,
    levels,
    setLevels,
    feeStructure,
    taxRates,
    inventory,
    standardAdmissionOfferHasInventory,
    isStandard,
    isEditing,
    canEdit,
    requiredFieldErrorStatus,
    errors,
    findError,
    handleValid
}) {
    const discountInputRef = useRef(null);

    const pricingOpts = [
        {
            id: 1,
            value: "match_price",
            label: "Match base price",
        },
        {
            id: 2,
            value: "decrease_by_price",
            label: "$ Decrease",
        },
        {
            id: 3,
            value: "decrease_by_percent",
            label: "% Decrease",
        },
        {
            id: 4,
            value: "custom_price",
            label: "Custom price",
        },
    ];

    const [show, setShow] = useState(false);

    const [level, setLevel] = useState();

    const [error, setError] = useState();

    useEffect(() => {
        setError(findError("discount"));
    }, [errors]);

    // focus discount input field whenever pricing option changes
    useEffect(() => {
        if (!discount) {
            if (discountInputRef.current) {
                discountInputRef.current.focus();
            }
        }
    }, [discount, opt]);

    const handlePriceLevels = (e, id, val = e.target?.value) => {
        if (e.target) {
            const name = e.target.name;
            setLevels((prevState) => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    [name]: val,
                },
            }));
        }
    };

    const handleShow = (level) => {
        setShow(true);
        setLevel(level);
    };

    const handleClose = () => {
        setShow(false);
    };

    return (
        <>
            <div className='card-body-heading card-body-heading--sm'>
                <div className='flex'>
                    <Card.Title as='h5' className='card-title-sm'>
                        Pricing & Fees
                    </Card.Title>
                    {(error || requiredFieldErrorStatus?.discount) && <RequiredText />}
                </div>
            </div>
            <>
                {inventory?.type && (
                    <>
                        {inventory?.type?.toLowerCase() == "open" ? (
                            <>
                                {!standardAdmissionOfferHasInventory && (
                                    <WarningCard
                                        text='Scaling not complete. Make sure all seats are assigned to price levels before continuing'
                                        variant='danger'
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                {isEditing ? (
                                    <WarningCard
                                        text='You are able to modify the seats assigned to this offer on the seat map page'
                                        variant='primary'
                                    />
                                ) : (
                                    <WarningCard
                                        text='After creating this offer, you must proceed to the seat map to assign seats to this offer'
                                        variant='primary'
                                    />
                                )}
                            </>
                        )}
                    </>
                )}

                <div className='actions-group-flex mb-4'>
                    <Form.Group className='form-group' controlId='options'>
                        <div className='form-label--flex mb-0'>
                            <Form.Label className='form-label--lowercase form-label-sm'>
                                Options
                            </Form.Label>
                            <Form.Select
                                name='pricingOption'
                                value={opt}
                                onChange={(e) => setOpt(e.target.value)}
                                disabled={isStandard || !canEdit}
                            >
                                {pricingOpts.map((opt) => (
                                    <option key={opt.id} value={opt.value}>
                                        {opt.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>
                    {opt?.includes("decrease_by") && (
                        <>
                            <InputGroup
                                className={`w-16 ${error || requiredFieldErrorStatus?.discount
                                    ? "input-group-error"
                                    : ""
                                    }`}
                            >
                                <InputGroup.Text id='discountPrice-val'>
                                    {opt?.includes("price") ? "$" : "%"}
                                </InputGroup.Text>
                                <Form.Control
                                    ref={discountInputRef}
                                    id='discountPrice-val'
                                    name='discount'
                                    aria-describedby='discountPrice-val'
                                    disabled={!canEdit}
                                    pattern={opt?.includes("price") ? "^[0-9.]*$" : "^[0-9]*$"}
                                    value={discount}
                                    onChange={(e) =>
                                        setDiscount(
                                            e.target.validity.valid || e.target.value === ""
                                                ? e.target.value
                                                : discount
                                        )
                                    }
                                    onBlur={handleValid}
                                />
                            </InputGroup>

                            <span className='info-text info-text-md info-text-primary caption-bold'>
                                Set {opt?.includes("price") ? "$" : "%"} discount price
                            </span>
                        </>
                    )}
                </div>
                {error && <Form.Text className='error'>{error?.message}</Form.Text>}
                <div className='list-table seven-col' role='table'>
                    <div
                        className='flex-row list-table-header justify-content-evenly'
                        role='rowgroup'
                    >
                        <div
                            className='list-table-col list-table-col-header'
                            role='columnheader'
                        >
                            <span>Price levels</span>
                        </div>
                        <div
                            className='list-table-col list-table-col-header  '
                            role='columnheader'
                        >
                            <span>Base price</span>
                        </div>
                        <div
                            className='list-table-col list-table-col-header'
                            role='columnheader'
                        >
                            <span>Seats</span>
                        </div>
                        <div
                            className='list-table-col list-table-col-header'
                            role='columnheader'
                        >
                            <span>Offer price</span>
                        </div>
                        <div
                            className='list-table-col list-table-col-header'
                            role='columnheader'
                        >
                            <span>Facility fee</span>
                        </div>
                        <div
                            className='list-table-col list-table-col-header text-center'
                            role='columnheader'
                        >
                            <span>Breakdown</span>
                        </div>
                    </div>
                    {levels &&
                        Object.values(levels).map((level) => (
                            <Level
                                key={level.id}
                                level={level}
                                isDisabled={isStandard}
                                isPriceEditable={opt?.includes("custom")}
                                canEdit={canEdit}
                                hasDiscountError={Boolean(error)}
                                handleChange={handlePriceLevels}
                                handleShow={handleShow}
                            />
                        ))}
                </div>
            </>
            <BreakdownModal
                show={show}
                handleClose={handleClose}
                level={level}
                pricingOption={pricingOpts?.find((option) => option.value === opt)}
                discount={discount}
                feeStructure={feeStructure}
                taxRates={taxRates}
            />
        </>
    );
}
