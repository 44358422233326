import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import ScalingIncompleteModal from "./ScalingIncompleteModal/ScalingIncompleteModal";
import { useNavigate } from "react-router-dom";

// Create the context
export const InventoryContext = createContext(undefined);

export const useInventory = () => {
    const context = useContext(InventoryContext);
    if (!context) {
        throw new Error("Context must be used within a InventoryProvider");
    }
    return context;
};

// Create the provider component
export const InventoryProvider = ({ children, activeTab, eventId }) => {
    const HOLDSCOLOR = "#EF466F";
    const KILLSCOLOR = "#B1B5C4";
    const SOLDCOLOR = "#45B26B";
    const [holds, setHolds] = useState({});
    const [kills, setKills] = useState({});
    const [sold, setSold] = useState({});
    // only exclusive offers to easily access these offers in seat map
    const [exclusiveOffers, setExclusiveOffers] = useState({});
    const [offers, setOffers] = useState({});
    const [priceLevels, setPriceLevels] = useState({});
    // unassigned seats for scaling menu to be able to display in move seats popup when deleting pricing level
    const [unassignedSeats, setUnassignedSeats] = useState({});
    // SelectedSeatIds is a bit more complicated, it has separation for seats and GASeats
    // and It needs to know where everything is being moved from
    const [selectedSeatIds, setSelectedSeatIds] = useState({});
    const [showScalingIncompleteModal, setShowScalingIncompleteModal] =
        useState(false);
    const navigate = useNavigate();

    // Create semi efficient inventoryLookupTable
    const inventoryLookupTable = useMemo(() => {
        const createLookup = (items, type) =>
            items.flatMap((item) => {
                const mainKey = `${type}-${item.id}`;
                const mainSeats = item.seats.map((seatId) => [
                    seatId,
                    { ...item, key: mainKey },
                ]);

                const subItems = item.categories
                    ? Object.entries(item.categories).flatMap(([subId, subItem]) => {
                        const subKey = `${mainKey}-${subId}`;
                        return subItem.seats.map((seatId) => [
                            seatId,
                            { ...subItem, key: subKey },
                        ]);
                    })
                    : [];

                return [...mainSeats, ...subItems];
            });

        const soldLookup = new Set(
            Object.values(sold).flatMap((item) => item.seats)
        );

        const lookupEntries = [
            ...createLookup(Object.values(holds), "h"),
            ...createLookup(Object.values(kills), "k"),
            ...createLookup(Object.values(sold), "s"),
            ...createLookup(Object.values(offers), "o").filter(
                ([seatId]) => !soldLookup.has(seatId)
            ),
        ];

        return Object.fromEntries(lookupEntries);
    }, [holds, kills, sold, offers]);

    const selectedLookupTable = useMemo(() => {
        // Handle regular seats
        const regularSelectedSeats = selectedSeatIds?.seats
            ? Object.values(selectedSeatIds.seats).flat()
            : [];

        // Combine regular and GA seats
        const allSelectedSeats = [...regularSelectedSeats];

        return new Set(allSelectedSeats);
    }, [selectedSeatIds]);

    // Create semi efficient scalingLookupTable
    const scalingLookupTable = useMemo(() => {
        const createLookup = (items) =>
            items.flatMap((item) =>
                item.seats.map((seatId) => [seatId, { ...item, key: item.id }])
            );

        const priceLevelEntries = priceLevels
            ? createLookup(Object.values(priceLevels))
            : [];

        const unassignedEntries = unassignedSeats
            ? Object.keys(unassignedSeats).map((seatId) => [seatId, "unassigned"])
            : [];

        return Object.fromEntries([...priceLevelEntries, ...unassignedEntries]);
    }, [priceLevels, unassignedSeats]);

    const getSeatItem = (seatId) => {
        // Check if selected
        let seatItem;
        // check other lookups
        if (activeTab === "scaling") {
            seatItem = scalingLookupTable[seatId] || null;
        } else {
            seatItem = inventoryLookupTable[seatId] || null;
        }

        if (selectedLookupTable.has(seatId)) {
            seatItem = {
                ...seatItem,
                color: "rgba(20, 20, 22, 1)",
                selected: true,
            };
        }

        return seatItem;
    };

    const getGAPLIDFrom = (sectionId) => {
        for (const priceLevelId in priceLevels) {
            const priceLevel = priceLevels[priceLevelId];
            if (priceLevel.gaSeats && sectionId in priceLevel.gaSeats) {
                return priceLevelId;
            }
        }
        return null; // Return null if no matching price level is found
    };

    const getGASectionColor = (sectionId) => {
        for (const priceLevelId in priceLevels) {
            const priceLevel = priceLevels[priceLevelId];
            if (priceLevel.gaSeats && sectionId in priceLevel.gaSeats) {
                return priceLevel.color;
            }
        }

        return null; //
    };

    const clearSelectedSeats = () => {
        setSelectedSeatIds({});
    };

    const isSectionSelected = (sectionId) => {
        // Check if the sectionId exists as a key in the gaSeats map of selectedSeatIds
        return (
            selectedSeatIds &&
            selectedSeatIds.gaSeats &&
            selectedSeatIds.gaSeats.hasOwnProperty(sectionId)
        );
    };

    const isDroppableInSelected = (id) => {
        // Check in selectedSeatIds.seats
        if (
            selectedSeatIds?.seats &&
            Object.keys(selectedSeatIds.seats).includes(id)
        ) {
            return true;
        }

        // Check in selectedSeatIds.gaSeats
        if (selectedSeatIds?.gaSeats) {
            for (const sectionId of Object.keys(selectedSeatIds.gaSeats)) {
                for (const subSectionId of Object.keys(
                    selectedSeatIds.gaSeats[sectionId]
                )) {
                    if (
                        subSectionId === id &&
                        selectedSeatIds.gaSeats[sectionId][subSectionId]?.length > 0
                    ) {
                        return true;
                    }
                }
            }
        }

        // If not found in either, return false
        return false;
    };

    // update exclusive offer state whenever offers state changes
    useEffect(() => {
        // update only exclusive offers
        // remove first offer
        const updatedOffersCopy = { ...offers };
        const firstKey = Object.keys(updatedOffersCopy)[0];
        delete updatedOffersCopy[firstKey];
        setExclusiveOffers(updatedOffersCopy);
    }, [offers]);

    const openScalingIncompleteModal = () => {
        setShowScalingIncompleteModal(true);
    };

    const scalingIncompleteModalLeaveAnyway = () => {
        setShowScalingIncompleteModal(false);
        navigate(`/myevent/${eventId}/offers`);
    };

    const scalingIncompleteModalContinueEditing = () => {
        setShowScalingIncompleteModal(false);
    };

    return (
        <InventoryContext.Provider
            value={{
                HOLDSCOLOR,
                KILLSCOLOR,
                SOLDCOLOR,
                holds,
                setHolds,
                kills,
                setKills,
                sold,
                setSold,
                exclusiveOffers,
                setExclusiveOffers,
                offers,
                setOffers,
                priceLevels,
                setPriceLevels,
                unassignedSeats,
                setUnassignedSeats,
                selectedSeatIds,
                setSelectedSeatIds,
                scalingLookupTable,
                getSeatItem,
                clearSelectedSeats,
                getGAPLIDFrom,
                isSectionSelected,
                getGASectionColor,
                isDroppableInSelected,
                openScalingIncompleteModal,
            }}
        >
            {children}
            <ScalingIncompleteModal
                show={showScalingIncompleteModal}
                leaveAnyway={scalingIncompleteModalLeaveAnyway}
                continueEditing={scalingIncompleteModalContinueEditing}
            />
        </InventoryContext.Provider>
    );
};
