import React from 'react';

import { formatNumber } from './../../../../utilities/helpers';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';

import { CheckboxEvent } from './CheckboxEvent';
import { LoadingContainer } from '../../../LoadingContainer';

export default function AddEventsModal({ show, isLoading, events, timezone, isSameAsInitial, selected, isChecked, handleCheck, handleSelectAll, checker, handleAdd, handleClose, handleCancel }) {

    return (
        <Modal className='modal-xl modal-full' scrollable centered animation={false} backdrop="static" fullscreen="md-down" show={show} onHide={handleClose} contentClassName='h-xl'>
            <div className='header'>
                <div className="header--flex">
                    <Modal.Title as="h4">Add events to your package</Modal.Title>
                    <CloseButton onClick={handleClose} />
                </div>
            </div>
            <Modal.Body>
                {isLoading ? (
                    <LoadingContainer />
                ) : (
                    <>
                        {events?.length > 0 ? (
                            <Stack gap={3} className='events-container'>
                                <span className='normal'><span className='normal-bold'>Events</span> ({formatNumber(events?.length)})</span>
                                <Form.Check
                                    label="Select all"
                                    type="checkbox"
                                    checked={checker(events)}
                                    id="all"
                                    className='mb-0 ms-3'
                                    onChange={handleSelectAll}
                                />
                                <Stack as="ul" className='events-list'>
                                    {events?.map((event, idx) => (
                                        <CheckboxEvent key={idx} event={event} timezone={timezone} isChecked={isChecked} handleCheck={handleCheck} />
                                    ))}
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack className='flex-grow-1 justify-content-center align-items-center'>
                                <h1 className='fs-md fs-bold text-center'>No events found.</h1>
                                <p className='subtitle mt-0'>Create an event for this configuration.</p>
                            </Stack>
                        )}
                    </>
                )}
            </Modal.Body>
            {events?.length > 0 && (
                <Stack direction="horizontal" className="btn-group-flex justify-content-between">
                    <span className='normal normal-bold'>Events selected ({selected?.length})</span>
                    <Stack direction='horizontal' gap={3}>
                        <Button
                            variant="outline-light"
                            size="lg"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="lg"
                            disabled={selected?.length === 0 || isSameAsInitial}
                            onClick={handleAdd}
                        >
                            Add events
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Modal>
    );
}
