import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import LoadingContext from "../../context/Loading/Loading";
import AuthService from "../../utilities/services/auth.service";
import UserContext from "../../context/User/User";
import EventDetailsContext from "../../context/EventDetails/EventDetails";

import { getEvent, getEventDiscount, removeOffer } from "../../utilities/api";
import {
    checkPermission,
    getNowInTimezone,
    getTimezoneDate,
} from "../../utilities/helpers";

import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { EmptyContainer } from "../EmptyContainer";
import { CreateOfferWrapper } from "./CreateOfferWrapper";
import { Offers } from "./Offers";
import { NoPermissionsContainer } from "../NoPermissionsContainer";
import { PageLoadingContainer } from "../PageLoadingContainer";
import { Discounts } from "./Discounts";
import { CreateOfferModal } from "./CreateOfferModal";
import { UpdatingAlert } from "../UpdatingAlert";

export default function OffersWrapper({ eventId, id }) {
    const navigate = useNavigate();
    const location = useLocation();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext);

    const { orgPermissions } = useContext(UserContext);

    const {
        updateEvent,
        updateCanPublish,
        standardAdmissionOfferHasInventory,
        updateIsEventOnsale,
        updateIsEventPublished,
        updateHasEventEnded,
        updateIsEventSoldout,
        setIsGAOnlyEvent,
        isEventPublished,
        isEventOnsale,
    } = useContext(EventDetailsContext);

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [event, setEvent] = useState();

    const [openInventory, setOpenInventory] = useState();

    // offer type
    const [offerType, setOfferType] = useState();

    // all offers
    const [offers, setOffers] = useState();

    // all discounts
    const [discounts, setDiscounts] = useState(null);

    // current offer
    const [offer, setOffer] = useState();

    // flag to check whether changes to offer has been made or new offer has been created to check whether to fetch data again
    // to tell if back button was clicked
    const [isChangesMade, setIsChangesMade] = useState(false);

    const [isRemoving, setIsRemoving] = useState(false);

    // create offer modal
    const [show, setShow] = useState(false);

    const offerStatuses = {
        1: {
            type: "onsale",
            label: "On Sale",
        },
        2: {
            type: "scheduled",
            label: "Scheduled",
        },
        3: {
            type: "ended",
            label: "Ended",
        },
        4: {
            type: "soldout",
            label: "Sold Out",
        },
    };

    useEffect(() => {
        if (orgPermissions?.length > 0)
            setHasPermission(checkPermission(orgPermissions, getPermissions(), 3));
    }, [orgPermissions]);

    // only runs on initial load
    useEffect(() => {
        console.log("getData");
        getData();
    }, []);

    // on location change
    useEffect(() => {
        // viewing all offers
        if (
            !location.pathname.includes("edit") &&
            !location.pathname.includes("create")
        ) {
            setOffer();
            setOfferType();
            if (isChangesMade) {
                console.log("is changes made");
                getData();
                setIsChangesMade(false);
            }
        }
    }, [location, isChangesMade]);

    // get offer on refresh if editing
    useEffect(() => {
        if (location.pathname.includes("edit") && !offer) {
            setOffer(offers?.find((offer) => offer.id === Number(id)));
        }
    }, [location, offers]);

    const getData = () => {
        showLoading();
        getEvent(eventId)
            .then((res) => {
                console.log("get event", res?.data);
                setEvent(res.data);
                setOffers(res.data?.offers);
                getEventOfferDiscounts();
                if (res?.data?.offers?.length > 0)
                    setOpenInventory(Object.values(res?.data?.inventory?.offers)[0]);
                // update context
                updateEvent(res?.data)
                setIsGAOnlyEvent(res?.data?.seatmap?.ga_only);
                updateCanPublish()
                updateIsEventOnsale()
                updateHasEventEnded(res?.data)
                updateIsEventSoldout(res?.data)
                updateIsEventPublished()
            })
            .catch((err) => {
                console.error(err);
                hideLoading();
            });
    };

    const getEventOfferDiscounts = async () => {
        let res = await getEventDiscount(eventId);
        console.log("getEventDiscounts res: ", res.data);
        setDiscounts(res.data.discounts);
        hideLoading();
    };

    // create offer modal
    const handleClose = () => setShow(false);

    // handle different views
    const handleClick = (_, id, offer) => {
        if (!id) {
            navigate("create");
            setOffer();
        } else {
            setOffer(offer);
            navigate(`edit?id=${id}`);
        }
    };

    const handleCreate = (choice) => {
        setOfferType(choice);
        handleClose();
        handleClick();
    };

    // get offer status
    const getOfferStatus = (period, offer) => {
        console.log("period", period);
        if (period) {
            const { starts, ends } = period;
            console.log(starts, ends, isEventPublished);
            let key;

            // ended - end time is same or before now  
            if (
                ends &&
                getTimezoneDate(ends, event?.timezone).isSameOrBefore(getNowInTimezone(event?.timezone))
            ) {
                key = 3;
            }

            // scheduled if event is not published
            else if (!isEventPublished) {
                key = 2;

                // event is published
            } else {
                console.log(isEventOnsale);
                // TODO: hook up cancelled status - scheduled or onsale but offer dates have not passed ?

                // sold out and end time is after current time (has not ended)
                if (offer?.isSoldOut) {
                    key = 4;
                }

                // onsale
                // start date is same or before now - we know that offer has not ended so we don't have to check that again 
                else if (
                    starts &&
                    getTimezoneDate(starts, event?.timezone).isSameOrBefore(getNowInTimezone(event?.timezone))
                ) {
                    key = 1;
                }

                // scheduled
                else {
                    key = 2;
                }
            }
            console.log(key);
            // get status based on key
            return offerStatuses[key];
        }
    };

    // get all price level seats (regular and ga seats), includes holds/kills seats - event price levels
    // get all seats from offer (regular and ga seats), not including holds/kills seats - open inventory
    const getSeatCount = (obj) => {
        if (obj) {
            const seats = obj?.seats;
            const gaSeats = Object.values(obj?.gaSeats)?.flatMap((seats) => seats);
            return [...seats, ...gaSeats]?.length;
        }
    };

    // get availability periods of offer
    // all offers will have the availability periods of Standard Admission offer by default
    const getAvailabilityPeriods = (currentOffer) => {
        let availabilityPeriods = [];

        if (offers && offers?.length === 0) return;

        // get default general onsale periods
        if (offers) availabilityPeriods = offers[0]?.availability;

        // existing offer
        // change the default periods to have the same start and end dates as periods in Standard Admission offer
        if (currentOffer) {
            // if offer is Standard Admission, just return its availability periods
            // change to compare offer[0] id to current offer id
            if (offers[0]?.id === currentOffer?.id) {
                return currentOffer?.availability;
            }

            const currentOfferPeriods = currentOffer?.availability;
            const customAvailabilityPeriods = currentOfferPeriods?.filter(
                (period) => !period?.name?.includes("General On-sale")
            );
            const defaultAvailabilityPeriods = currentOfferPeriods?.filter((period) =>
                period?.name?.includes("General On-sale")
            );
            const updatedDefaultAvailabilityPeriods = defaultAvailabilityPeriods?.map(
                (period, idx) => {
                    return {
                        ...period,
                        starts: availabilityPeriods[idx]?.starts,
                        ends: availabilityPeriods[idx]?.ends,
                    };
                }
            );
            availabilityPeriods = [
                ...updatedDefaultAvailabilityPeriods,
                ...(customAvailabilityPeriods?.length > 0
                    ? customAvailabilityPeriods
                    : []),
            ];
        }
        return availabilityPeriods;
    };

    // update offer price depending on pricing option
    const getDiscountedPrice = (price, pricingOpt, discount) => {
        console.log(discount, price);
        switch (pricingOpt) {
            // take away amount from price
            case "decrease_by_price":
                return price - parseFloat(discount);

            // get percentage of price and take it away from price
            case "decrease_by_percent":
                return Math.abs((parseFloat(discount) / 100) * price - price);

            default:
                break;
        }
    };

    // handle applying discount to offer price if new price levels added or price is updated on price levels
    // if no discount, get offer price
    const getOfferPrice = (offerLevels, idx, pricingOption, discount) => {
        console.log(offerLevels);
        const offerPrice = offerLevels[idx]?.offerPrice?.toString();
        const basePrice = offerLevels[idx]?.basePrice?.toString();
        // if offer has discount
        if (discount) {
            console.log(
                getDiscountedPrice(basePrice, pricingOption, discount),
                offerPrice
            );
            // check if discount is already applied to offer price - only if discount price is the same as offer price
            if (
                getDiscountedPrice(basePrice, pricingOption, discount) == offerPrice
            ) {
                return offerPrice;
            }
            // new price level or updated price
            else {
                console.log("apply discount");
                // apply discount on basePrice
                return getDiscountedPrice(basePrice, pricingOption, discount);
            }
        } else {
            // offer does not have discount

            // if pricing option is custom price return offer price
            if (pricingOption === "custom_price") {
                return offerPrice;
            }
            // else return base price
            else {
                return basePrice;
            }
        }
    };

    const deleteOffer = async (id, handler) => {
        setIsRemoving(true);
        try {
            // Delete offer
            const result = await removeOffer(id);
            console.log("REMOVEoffer; res: ", result);
            console.log("----");
            console.log("----");
            console.log("----");
            handler();
            return result;
        } catch (error) {
            throw error; // Re-throw to be handled by caller
        } finally {
            setIsRemoving(false);
        }
    };

    console.log("event status: ", event?.status);
    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section
                        className={`max-width-wrapper event-form ${!hasPermission ? "overlay" : ""
                            }`}
                    >
                        {!location.pathname.includes("/create") &&
                            !location.pathname.includes("/edit") ? (
                            <section>
                                <header className='section-header-sm section-heading--flex section-heading section-heading--secondary'>
                                    <h1>Offers</h1>
                                    {offers?.length > 0 && (
                                        <Button
                                            size='lg'
                                            disabled={
                                                event?.status === "complete" ||
                                                !standardAdmissionOfferHasInventory
                                            }
                                            onClick={() => setShow(true)}
                                            className='ms-auto'
                                        >
                                            Create offer
                                        </Button>
                                    )}
                                </header>
                                {offers?.length > 0 ? (
                                    <Stack gap={4}>
                                        <Offers
                                            isEventOnsale={isEventOnsale}
                                            timezone={event?.timezone}
                                            offers={offers}
                                            openInventory={openInventory}
                                            handleClick={handleClick}
                                            getOfferPrice={getOfferPrice}
                                            getOfferStatus={getOfferStatus}
                                            getSeatCount={getSeatCount}
                                        />
                                        <Discounts
                                            discounts={discounts}
                                            offers={offers}
                                            eventTimezone={event?.timezone}
                                            getAvailabilityPeriods={getAvailabilityPeriods}
                                        />
                                        <UpdatingAlert isEventPublished={isEventPublished} eventUUID={eventId} />
                                    </Stack>
                                ) : (
                                    <Card body>
                                        <EmptyContainer style='center lg'>
                                            <p>
                                                You must finish scaling your tickes in order to create
                                                an offer.
                                            </p>
                                            <Button onClick={() => navigate("../seatmap")}>
                                                Go to Seat Map
                                            </Button>
                                        </EmptyContainer>
                                    </Card>
                                )}
                            </section>
                        ) : (
                            <CreateOfferWrapper
                                eventId={eventId}
                                event={event}
                                id={id}
                                isDiscountedOffer={offerType === "discounted"}
                                offers={offers}
                                currentOffer={offer}
                                openInventory={openInventory}
                                standardAdmissionOfferHasInventory={
                                    standardAdmissionOfferHasInventory
                                }
                                setIsChangesMade={setIsChangesMade}
                                getDiscountedPrice={getDiscountedPrice}
                                getAvailabilityPeriods={getAvailabilityPeriods}
                                getOfferPrice={getOfferPrice}
                                getOfferStatus={getOfferStatus}
                                getSeatCount={getSeatCount}
                                removeOffer={deleteOffer}
                                isRemoving={isRemoving}
                            />
                        )}
                    </section>

                    {!hasPermission && <NoPermissionsContainer />}
                </div>
            )}

            <CreateOfferModal
                show={show}
                offerType={offerType}
                handleClose={handleClose}
                handleCreate={handleCreate}
            />
        </>
    );
}
