import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual'

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';
import EventDetailsContext from '../../context/EventDetails/EventDetails';

import { checkPermission, b64toBlob, isEventPast, sortAttractions, scrollToTop } from '../../utilities/helpers';

import { getEvent, upload, createAttractions, addDetailsToEvent, updateAttraction, removeAttraction } from '../../utilities/api';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import { EventImage } from './EventImage';
import { AddAttraction } from './AddAttraction';
import { Description } from './Description';
import { Fees } from './Fees';
import { ResalePricePoints } from './ResalePricePoints';
import { TicketLimit } from './TicketLimit';
import { CreateEventButtons } from '../CreateEventButtons';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function EventSettingsWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { updateEvent, updateCanPublish, isEventPublished, updateIsEventPublished, updateIsEventOnsale, isEventOnsale, isEventSoldout, updateHasEventEnded, updateIsEventSoldout } = useContext(EventDetailsContext)

    const navigate = useNavigate()

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    // event image 
    const [image, setImage] = useState()

    const [attractions, setAttractions] = useState([])

    // used for flag if custom amount
    const [hasMinimumResale, setHasMinimumResale] = useState(null)

    // seperate state because I have to manipulate it based on hasMinimumResale state 
    const [minimumResaleAmount, setMinimumResaleAmount] = useState('')

    // used for flag if custom amount
    const [hasTicketLimit, setHasTicketLimit] = useState(null)

    // seperate state because I have to manipulate it based on hasTicketLimit state
    const [ticketLimit, setTicketLimit] = useState('')

    // decouple description from event summary
    const [description, setDescription] = useState('')

    const [initialState, setInitialState] = useState(false)

    const [event, setEvent] = useState()

    const [errors, setErrors] = useState([])

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [showFooter, setShowFooter] = useState(false)

    const [isSaving, setIsSaving] = useState(false)

    const [hasRequiredFieldError, setHasRequiredFieldError] = useState(false)

    const [requiredFieldErrorStatus, setRequiredFieldErrorStatus] = useState({})

    const [attractionsToUpdate, setAttractionsToUpdate] = useState([])

    const [attractionIdsToRemove, setAttractionIdsToRemove] = useState([])

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 3));

    }, [orgPermissions])

    useEffect(() => {
        showLoading();
        getEvent(eventId)
            .then((res) => {
                if (res.data) {
                    setEvent(res.data)
                    updateEvent(res.data)
                    updateIsEventOnsale()
                    updateHasEventEnded(res.data)
                    updateIsEventSoldout(res.data)
                    updateIsEventPublished()
                    setImage(res?.data?.image?.url)
                    if (res.data?.attractions) setAttractions(res.data?.attractions)
                    setDescription(res?.data?.summary || '')
                    // only do this if has image - means editing existing event 
                    const isEditing = res?.data?.image?.id;
                    if (isEditing) {
                        setHasMinimumResale(res?.data?.resaleEnableMinimum)
                        setMinimumResaleAmount(res?.data?.resaleMinimumPercentage || '')
                        setHasTicketLimit(res?.data?.ticketLimit)
                        setTicketLimit(res?.data?.globalTicketLimit || '')
                    }

                    // save initial state to check whether to show save buttons 
                    saveInitialState(
                        res?.data,
                        res?.data?.image?.url,
                        res?.data?.attractions,
                        res?.data?.summary || '',
                        isEditing ? res?.data?.resaleEnableMinimum : null,
                        res?.data?.resaleMinimumPercentage || '',
                        isEditing ? res?.data?.ticketLimit : null,
                        res?.data?.globalTicketLimit || ''
                    )
                }
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [eventId])

    useEffect(() => {
        // Listens for image upload
    }, [attractions])

    // show/hide footer 
    useEffect(() => {
        setShowFooter(initialState?.image !== image || !isEqual(initialState?.attractions, attractions) || initialState?.description !== description || parseFloat(initialState?.event?.facilityFees) !== parseFloat(event?.facilityFees) || initialState?.hasMinimumResale !== hasMinimumResale || (hasMinimumResale && parseFloat(initialState?.minimumResaleAmount) !== parseFloat(minimumResaleAmount)) || initialState?.hasTicketLimit !== hasTicketLimit || (hasTicketLimit && parseFloat(initialState?.ticketLimit) !== parseFloat(ticketLimit)))

    }, [initialState, image, event?.facilityFees, attractions, description, hasMinimumResale, minimumResaleAmount, hasTicketLimit, ticketLimit])

    useEffect(() => {
        if (hasRequiredFieldError) {
            updateRequiredFieldErrorStatus()
        }
    }, [image, attractions, event?.facilityFees, hasMinimumResale, minimumResaleAmount, hasTicketLimit, ticketLimit])

    useEffect(() => {
        if (hasRequiredFieldError) {
            // if all fields are filled in: dismiss alert 
            if (Object.keys(requiredFieldErrorStatus).every(key => !requiredFieldErrorStatus[key])) {
                closeAlert()
                setHasRequiredFieldError(false)
                setRequiredFieldErrorStatus({})
            }
        }
    }, [hasRequiredFieldError, requiredFieldErrorStatus])

    useEffect(() => {
        // close all alerts when fields are edited except for required fields alert 
        if (alert.show && errors?.length === 0 && !hasRequiredFieldError) {
            closeAlert()
        }
    }, [errors, hasRequiredFieldError])

    // remove errors for inputs when inputs change 
    useEffect(() => {
        if (errors) {
            const el = findError('minimumResale')
            if (el) setErrors(errors.filter(error => error.field !== el.field))
        }
    }, [minimumResaleAmount])

    useEffect(() => {
        if (errors) {
            const el = findError('ticketLimit')
            if (el) setErrors(errors.filter(error => error.field !== el.field))
        }
    }, [ticketLimit])

    // find error in errors array
    const findError = (field) => {
        return errors.find(error => error.field === field)
    }

    const updateRequiredFieldErrorStatus = () => {
        setRequiredFieldErrorStatus({
            'image': !image,
            'attractions': attractions?.length === 0,
            'fees': isNaN(parseFloat(event?.facilityFees)),
            'resale': !isValidMinimumResale(),
            'limit': !isValidTicketLimit()
        })
    }

    const closeAlert = () => {
        setAlert({ show: false, variant: '', message: '' })
    }

    const isEditable = () => {
        return isEventPast(event)
    }

    const handleChange = (e) => {
        if (e) setEvent({ ...event, [e.target.name]: e.target.value })
    }

    const handleValid = (e) => {
        const { name } = e.target;

        switch (name) {
            case 'minimumResale':
                if (minimumResaleAmount && parseFloat(minimumResaleAmount) < 30) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            message: 'Minimum resale price cannot be set below 30%'
                        }
                    ])
                }
                break;

            case 'ticketLimit':
                if (ticketLimit && (parseInt(ticketLimit) < 1 || parseInt(ticketLimit) > 50)) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            message: 'Ticket limit must be a number between 1 and 50'
                        }
                    ])
                }
                break;
            default:
                break;
        }
    }

    const saveInitialState = (event, image, attractions, description, hasMinimumResale, minimumResaleAmount, hasTicketLimit, ticketLimit) => {
        // save initial state to check whether to show save buttons
        setInitialState({
            event,
            image,
            attractions,
            description,
            hasMinimumResale,
            minimumResaleAmount,
            hasTicketLimit,
            ticketLimit
        })
    }

    const isValid = (hasField, field) => {
        // not null and is false or true and string is not empty
        return (hasField !== null && (!hasField || (hasField && field !== "")))
    }
    const isValidTicketLimit = () => {
        return isValid(hasTicketLimit, ticketLimit)
    }

    const isValidMinimumResale = () => {
        return isValid(hasMinimumResale, minimumResaleAmount)
    }

    const handleSave = (e) => {
        e.preventDefault()
        // if not showing footer, meaning nothing has changed, don't save 
        if (!showFooter) return;

        // if error, don't save 
        if (hasRequiredFieldError) {
            scrollToTop()
            return
        }
        const hasError = (!image || attractions?.length === 0 || isNaN(parseFloat(event?.facilityFees)) || !isValidTicketLimit() || !isValidMinimumResale())

        if (hasError) {
            updateRequiredFieldErrorStatus()
            setHasRequiredFieldError(hasError)
            scrollToTop()
            setAlert({
                show: true,
                variant: 'danger',
                message: 'You are missing required subject fields. Please fill out all required fields before continuing.'
            })
            return
        }

        // if other errors 
        if (errors?.length > 0) {
            scrollToTop()
            setAlert({
                show: true,
                variant: 'danger',
                message: 'You have input errors. Please correct all input errors before continuing.'
            })
            return
        }

        setIsSaving(true)

        // adds attractions to event 
        attractions.map((attraction) => {
            // Will only create attractions if url is not already created  
            if (attraction.artwork.url.startsWith("https://")) return
            console.log('create', attraction);
            let data = {}
            // Split the base64 string in data and contentType
            const block = attraction.artwork.url.split(";");
            // Get the content type of the image
            const contentType = block[0].split(":")[1];// In this case "image/gif"
            // get the real base64 content of the file
            const realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
            const formData = new FormData();
            // Convert it to a blob to upload
            const blob = b64toBlob(realData, contentType);
            console.log(blob);
            formData.append(`files.artwork`, blob);
            data['primary'] = attraction.primary;
            data['name'] = attraction.name;
            data['order'] = attraction.order;
            data['eventUUID'] = eventId;
            formData.append('data', JSON.stringify(data));
            createAttractions(formData)
        })

        let data = {};
        data['description'] = description || null;
        data['eventUUID'] = eventId;
        data['facilityFees'] = parseFloat(event?.facilityFees);
        data['resaleEnableMinimum'] = hasMinimumResale;
        data['resaleMinimumPercentage'] = hasMinimumResale ? minimumResaleAmount : undefined
        data['ticketLimit'] = hasTicketLimit
        data['globalTicketLimit'] = ticketLimit != '' ? ticketLimit : null;

        // add main event image to event 
        if (image && !image.startsWith('https://')) { // new or updating image 
            const formData = new FormData();
            const ImageURL = image;
            // Split the base64 string in data and contentType
            const block = ImageURL.split(";");
            // Get the content type of the image
            const contentType = block[0].split(":")[1];// In this case "image/gif"
            // get the real base64 content of the file
            const realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

            // Convert it to a blob to upload
            const blob = b64toBlob(realData, contentType);
            formData.append(`files`, blob);
            upload(formData)
                .then((res) => {
                    data['image'] = res?.data[0]?.id;
                    addDetailsToEvent({ data })
                        .then((res) => {
                            setImage(res?.data?.image?.url)
                            setIsSaving(false)
                            // update event in context 
                            updateEventContext()
                            updateCanPublish(res?.data?.image?.id)
                            updateIsEventOnsale(res.data)
                            updateHasEventEnded(res.data)
                            updateIsEventSoldout(res.data)
                            updateIsEventPublished()

                            // save initial state again when updating to not show footer 
                            saveInitialState(
                                event,
                                res?.data?.image?.url,
                                attractions,
                                description,
                                hasMinimumResale,
                                minimumResaleAmount,
                                hasTicketLimit,
                                ticketLimit
                            )

                            scrollToTop()
                            setAlert({
                                show: true,
                                variant: 'success',
                                message: 'Your details have been updated.'
                            })

                            // event does not have attractions and event has no offers
                            if (event?.attractions?.length === 0 && event?.offers?.length === 0) {
                                navigate(`/myevent/${eventId}/seatmap`)
                            }
                        })
                        .catch((err) => {
                            console.error(err)
                            setIsSaving(false)
                            scrollToTop()
                            setAlert({
                                show: true,
                                variant: 'danger',
                                message: 'Unable to save details. Please try again.'
                            })
                        })
                })
            // update with existing image 
        } else {
            data['image'] = event?.image?.id; // take existing image

            addDetailsToEvent({ data }) // Updating event without changing event image
                .then(async (res) => { // Make the function async
                    // if there are any attractions to be deleted 
                    if (attractionIdsToRemove?.length > 0) {
                        try {
                            await Promise.all(attractionIdsToRemove.map(id => removeAttraction(id)));
                        } catch (err) {
                            // This will now throw an error that can be caught in the outer catch
                            throw new Error("Failed to remove attraction: " + err);
                        }
                    }

                    // if there are any attractions to be updated 
                    if (attractionsToUpdate?.length > 0) {
                        try {
                            let data = {};
                            await Promise.all(attractionsToUpdate.filter(attraction => !attractionIdsToRemove.some(id => attraction.id === id)).map((attraction) => {
                                data['id'] = attraction.id;
                                data['name'] = attraction.name;
                                data['primary'] = attraction.primary;
                                data['order'] = attraction.order
                                updateAttraction({ data })
                            }))
                        } catch (err) {
                            // This will now throw an error that can be caught in the outer catch
                            throw new Error("Failed to remove attraction: " + err);
                        }
                    }

                    // Update event in context 
                    updateEventContext(true);

                    // Save initial state again when updating to not show footer 
                    saveInitialState(
                        event,
                        image,
                        attractions,
                        description,
                        hasMinimumResale,
                        minimumResaleAmount,
                        hasTicketLimit,
                        ticketLimit
                    );

                    setIsSaving(false);
                    setAttractionIdsToRemove([])
                    setAttractionsToUpdate([])
                    scrollToTop();
                    setAlert({
                        show: true,
                        variant: 'success',
                        message: 'Your details have been updated.'
                    });
                })
                .catch((err) => {
                    console.error(err);
                    setIsSaving(false);
                    scrollToTop();
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save details. Please try again.'
                    });
                });
        }
    }

    const updateEventContext = (isEditing = false) => {
        console.log(isEditing);
        // get event to get all attractions 
        getEvent(eventId)
            .then((res) => {
                updateEvent(res?.data)
                console.log(res?.data?.attractions);
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper event-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5">
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <header className="section-header section-heading section-heading--secondary">
                            <h1>Event Settings</h1>
                        </header>
                        <Form onSubmit={handleSave}>
                            <Card body className='card--md'>
                                <EventImage eventName={event?.name} image={image} setImage={setImage} isDisabled={isEditable()} requiredFieldErrorStatus={requiredFieldErrorStatus} />
                            </Card>
                            <Card body className='card--md'>
                                <AddAttraction event={event} attractions={sortAttractions(attractions)} setAttractions={setAttractions} isDisabled={isEditable()} requiredFieldErrorStatus={requiredFieldErrorStatus} toUpdate={attractionsToUpdate} setToUpdate={setAttractionsToUpdate} idsToRemove={attractionIdsToRemove} setIdsToRemove={setAttractionIdsToRemove} />
                            </Card>
                            <Card body className='card--md'>
                                <Description description={description} setDescription={setDescription} isDisabled={isEditable()} />
                            </Card>
                            <Card body className='card--md'>
                                <Fees event={event} handleChange={handleChange} isDisabled={isEditable() || isEventOnsale || isEventSoldout} requiredFieldErrorStatus={requiredFieldErrorStatus} />
                            </Card>
                            <Card body className='card--md'>
                                <ResalePricePoints
                                    hasMinimumResale={hasMinimumResale}
                                    setHasMinimumResale={setHasMinimumResale}
                                    minimumResaleAmount={minimumResaleAmount}
                                    setMinimumResaleAmount={setMinimumResaleAmount}
                                    isDisabled={isEditable() || isEventSoldout}
                                    errors={errors}
                                    requiredFieldErrorStatus={requiredFieldErrorStatus}
                                    handleValid={handleValid}
                                    findError={findError}

                                />
                            </Card>
                            <Card body className='card--md'>
                                <TicketLimit
                                    ticketLimit={ticketLimit}
                                    setTicketLimit={setTicketLimit}
                                    hasTicketLimit={hasTicketLimit}
                                    setHasTicketLimit={setHasTicketLimit}
                                    errors={errors}
                                    isDisabled={isEditable() || isEventPublished}
                                    requiredFieldErrorStatus={requiredFieldErrorStatus}
                                    handleValid={handleValid}
                                    findError={findError}
                                />
                            </Card>
                        </Form>
                    </section>
                    {showFooter && (
                        <CreateEventButtons isEditing={(event?.image?.id || event?.offers?.length > 0)} isSaving={isSaving} handleSave={handleSave} styles={`${!hasPermission ? 'overlay' : ''} `} />
                    )}
                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
